<template>
    <Sidebar />
</template>
<script>
    import Sidebar from "_lib/components/layouts/Sidebar.vue";
    export default {
        name: "TemplateWrapper",
        components: {
            Sidebar
        }
    };
</script>