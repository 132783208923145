<template>
    <a :href="$store.state.siteURL" target="_blank" class="text-xs font-medium px-2 sm:px-5 h-full flex items-center whitespace-nowrap" :class="cls">
        <font-awesome-icon icon="store" class="text-lg mr-2" />
        Siteyi Gör
    </a>
</template>

<script>
export default {
    computed: {
        cls() {
            return this.$store.state.screen == 'xxl' ? 'header-btn-item' : '';
        }
    }
};
</script>