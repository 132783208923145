<template>
    <a-spin tip="Loading..." size="large" :spinning="!isReady" v-if="!isReady" class="w-full h-full min-h-full" />
    <div class="w-full data-grid-wrapper" v-else>
        <div class="bg-white dark:bg-gray-900 rounded-md shadow data-grid-nav p-2 md:p-5 mb-3 flex items-center" v-if="title || isNav" :class="title && isNav ? 'justify-between' : (!title && isNav ? 'justify-end' : 'justify-start')">
            <h1 class="text-yellow-500 text-sm md:text-xl">
                <font-awesome-icon :icon="titleIcon" v-if="titleIcon" class="mr-2" />
                {{ title }}
            </h1>
            <div class="flex items-center justify-end">
                <slot name="navButtons"></slot>
                <a-tooltip v-if="data?.editable && isAdd">
                    <template #title>Yeni Ekle</template>
                    <button class="w-9 h-9 bg-gray-100 text-gray-400 text-sm rounded-md hover-primary ml-2" @click="displayEditable({id: 0})">
                        <font-awesome-icon icon="plus" />
                    </button>
                </a-tooltip>
                <a-tooltip v-if="isRemove">
                    <template #title>Sil</template>
                    <button class="w-9 h-9 bg-gray-100 text-gray-400 text-sm rounded-md hover-primary ml-2" @click="deleteSelectedRows">
                        <font-awesome-icon icon="trash" />
                    </button>
                </a-tooltip>
                <a-tooltip v-if="isDrawer">
                    <template #title>Ayarlar</template>
                    <button class="w-9 h-9 bg-gray-100 text-gray-400 text-sm rounded-md hover-primary ml-2" @click="visibleDrawer = !visibleDrawer">
                        <font-awesome-icon icon="cog" />
                    </button>
                </a-tooltip>
            </div>
        </div>
        <a-row>
            <a-col :span="24" class="rel">
                <slot name="filter"></slot>
            </a-col>
        </a-row>
        <a-row v-if="isTableReady">
            <a-col :span="24">
                <a-table :indentSize="5" :ellipsis="true" :customRow="(record) => { return { onDblclick: rowDblClick }}" :row-selection="isMobile || !isCheck ? null : { columnWidth:'5%', selectedRowKeys:selectedRowKeys, onChange:onSelectChange,  selections:true, hideDefaultSelections:false }" :columns="columns" :data-source="dataStore" :pagination="pagination" @change="onTableChange" :sortDirections="['descend', 'ascend']" size="small" class="data-grid-table" rowKey="id" sticky :scroll="{ x: 860 }">
                    <template #bodyCell="{ text, column, record }">
                        <template v-if="typeof column.renderer==='undefined'">{{ text }}</template>
                        <template v-else-if="$slots[column.renderer]">
                            <slot :text="text" :column="column" :record="record" :name="column.renderer"></slot>
                        </template>
                        <template v-else-if="column.renderer === 'tac'">
                            <div class="data-grid-tac">{{ text }}</div>
                        </template>
                        <template v-else-if="column.renderer === 'language'">
                            {{ text.length > 0 ? $tm('language.' + text) : (column.emptyText || 'Default') }}
                        </template>
                        <template v-else-if="column.renderer === 'detail'">
                            <div class="data-grid-settings-wrapper">
                                <ul class="data-grid-setting-list">
                                    <li>
                                        <router-link :to="column.link + text" class="data-grid-link">
                                            <font-awesome-icon icon="pen" />
                                        </router-link>
                                    </li>
                                    <li v-if="record.link">
                                        <a target="_blank" :href="'/'+record.link" class="data-grid-link">
                                            <font-awesome-icon icon="link" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </template>
                        <template v-else-if="column.renderer === 'settings'">
                            <div class="data-grid-settings-wrapper">
                                <slot v-if="false==isMobile" :text="text" :record="record" name="data-grid-slot-setting-list"></slot>
                                <ul v-if="true==isMobile" class="data-grid-setting-list">
                                    <li @click="openSetting(record.id)">
                                        <font-awesome-icon icon="cog" />
                                    </li>
                                </ul>
                            </div>
                            <a-modal v-if="true==isMobile" :width="300" class="open-settings-modal" v-model:visible="visibleSettings[record.id]" okText="Kapat" cancelText="">
                                <slot :text="text" :record="record" name="data-grid-slot-setting-list"></slot>
                            </a-modal>
                        </template>
                        <template v-else-if="column.renderer === 'link'">
                            <a class="data-grid-link" target="_blank" :href="redirectDetail((column.link || '') + record[column.inx || column.dataIndex], column.internal || false)">
                                {{ text }}
                            </a>
                        </template>
                        <template v-else-if="column.renderer === 'format'">
                            <div class="data-grid-price">{{ format(text, record, column)}} {{record.currency || 'TL' }}</div>
                        </template>
                        <template v-else-if="column.renderer === 'date'">
                            <div class="data-grid-date">{{ timestampToDate(text, 'd.m.y h:i') }} </div>
                        </template>
                        <template v-else-if="column.renderer === 'status'">
                            <div v-if="parseInt(text)===1" class="data-grid-tac" @click="update(record.id, column.dataIndex,  0)">
                                <font-awesome-icon icon="check-circle" class="text-xl text-green-500" />
                            </div>
                            <div v-else class="data-grid-tac" @click="update(record.id, column.dataIndex,  1)">
                                <font-awesome-icon icon="times-circle" class="text-xl text-red-500" />
                            </div>
                        </template>
                        <template v-else-if="column.renderer === 'image'">
                            <div class="inline-block border border-gray-300">
                                <img v-if="text==''" src="https://celil.meticaret.com/celil/no-image.png" />
                                <img v-if="text!=''" :src="text.indexOf('http')>-1 ? text : $store.state.siteURL+'/'+text" />
                            </div>
                        </template>
                        <template v-else-if="column.renderer === 'editable'">
                            <a-input-search v-if="'input'==column.type" placeholder="Değer" :defaultValue="text" @search="update(record.id, column.dataIndex, $event)">
                                <template #enterButton>
                                    <a-button type="primary">
                                        <template #icon>
                                            <font-awesome-icon icon="save" />
                                        </template>
                                    </a-button>
                                </template>
                            </a-input-search>
                        </template>
                        <template v-else-if="column.renderer === 'combobox'">
                            <Combobox :name="column.dataIndex" :store="column.store || []" :placeholder="record.text" :value="text" :callback="comboChange" :id="record.id" />
                        </template>
                        <template v-else-if="column.renderer === 'settingsEditable'">
                            <ul class="data-grid-settings-editable">
                                <li @click="displayEditable(record)">
                                    <font-awesome-icon icon="pen" />
                                </li>
                                <li @click="deleteRow(record.id)">
                                    <font-awesome-icon icon="trash" />
                                </li>
                                <li v-if="record.link ?? record.seoLink" target="_blank">
                                    <a target="_blank" :href="`${$store.state.siteURL}/${record.link ?? record.seoLink}`">
                                        <font-awesome-icon icon="link" />
                                    </a>
                                </li>
                            </ul>
                        </template>
                        <template v-else>
                            <div v-html="text"></div>
                        </template>
                    </template>
                </a-table>
            </a-col>
        </a-row>
        <a-drawer v-model:visible="visibleDrawer" :title="$tm('common.settings')" :width="320">
            <slot name="drawer"></slot>
            <div v-for="col in configNew" :key="col.dataIndex" class="flex mb-2">
                <div class="w-5/12 text-sm text-gray-300">{{col.title || col.text}}</div>
                <div class="w-1/12 text-center text-sm text-gray-300">:</div>
                <div class="w-6/12">
                    <a-switch v-model:checked="col.active" @change="onColumnChange" />
                </div>
            </div>
        </a-drawer>
        <a-drawer v-model:visible="visibleEditable" v-if="visibleEditable" :title="editableData.title" :width="isMobile ? ($store.state.width || window.innerWidth) : (editableData.width || 600)">
            <Editable :data="editableData" v-model:errors="formErrors" />
        </a-drawer>
    </div>
</template>

<script>
    import { Modal } from 'ant-design-vue';
    import { GridApi } from "_lib/common/GridApi.js";
    export default {
        props: {
            title: String,
            titleIcon: String,
            data: Object
        },
        data() {
            return {
                isNew: false,
                myGrid: {
                    data: [],
                    visible: true,
                    params: {}
                },
                configNew: [],
                isAdd: true,
                isReady: false,
                isCheck: true,
                isNav: true,
                isRemove: true,
                isDrawer: true,
                isTableReady: false,
                columns: [],
                config: [],
                activeColumns: {},
                editableData: {},
                visibleDrawer: false,
                visibleEditable: false,
                visibleSeo: false,
                visibleSettings: {},
                selectedRowKeys: [],
                dataStore: [],
                dataStoreById: {},
                startIndex: 0,
                pagination: {
                    total: 100,
                    pageSize: 20,
                    showTotal: function(total) {
                        return "Toplam " + total + " Kayıt ";
                    },
                    current: 1,
                    position: ['bottomLeft']
                },
                formErrors: []
            };
        },
        computed: {
            isMobile() {
                return /xs|sm|md/ig.test(this.$store.state.screen) ? true : false;
            }
        },
        watch: {
            "myGrid.response": {
                handler: function(newVal, oldVal) {
                    // console.log(newVal);
                    // console.log(oldVal);
                    let a = JSON.stringify(newVal.data);
                    let b = JSON.stringify(oldVal ? oldVal.data : []);
                    if (a != b) {
                        this.setStore(newVal);
                    }
                },
                deep: true,
            },
            "myGrid.visible": {
                handler: function(newVal, oldVal) {
                    if (newVal.drawer != oldVal.drawer) {
                        this.visibleDrawer = newVal.drawer;
                    }
                    if (newVal.table != oldVal.table) {
                        this.isTableReady = newVal.table;
                    }
                },
                deep: true,
            },
            "myGrid.params": {
                handler: function(newParam, oldParam) {
                    if (newParam.page != oldParam.page) {
                        this.pagination.current = newParam.page;
                    }
                    if (newParam.isReady != oldParam.isReady) {
                        this.isTableReady = newParam.isReady;
                    }
                },
                deep: true,
            },
        },
        methods: {
            displayEditable(record) {
                this.visibleEditable = true;
                this.editableData.id = record.id;
            },
            fetch() {
                this.config = this.data.config || [];
                this.configNew = JSON.parse(JSON.stringify(this.data.config));
                this.gridRefresh(false);
                this.myGrid.reload().then(res => {
                    this.setStore(res);
                    this.dataStore = res.data;
                    this.isTableReady = true;
                    this.isReady = true;
                });
            },
            setStore(response) {
                this.dataStore = response.data;
                this.pagination.total = response.total;
                let dataStoreById = {};
                for (let x = 0; x < this.dataStore.length; x++) {
                    let a = this.dataStore[x];
                    dataStoreById[a.id] = a;
                }
                this.dataStoreById = dataStoreById;
            },
            deleteRow(id) {
                const self = this;
                let uri = self.data.deleteUrl ? self.data.deleteUrl + id : self.url.get(self.data.table, 'delete', id);
                Modal.confirm({
                    title: 'Dikkat !',
                    content: 'Kayıdı silmek istiyor musunuz?',
                    onOk() {
                        self.axios.get(uri).then(msg => {
                            self.isTableReady = false;
                            self.onResponse(msg).then(() => {
                                self.visibleEditable = false;
                                self.gridRefresh();
                            });
                            // self.myGrid.reload().then(() => {
                            //     self.onResponse(msg).then(() => {
                            //         self.isTableReady = true;
                            //     });
                            // });
                        });
                    },
                    onCancel() {},
                    cancelText: "Hayır",
                    okText: "Evet",
                });
            },
            deleteSelectedRows() {
                const self = this;
                if (self.selectedRowKeys.length < 1) {
                    self.message.error("Lütfen silinecek satırları seçiniz.");
                    return;
                }

                let ids = self.selectedRowKeys.join("-");
                let uri = self.data.deleteUrl ? self.data.deleteUrl + ids : self.url.get(self.data.table, 'delete', ids);

                Modal.confirm({
                    title: 'Dikkat !',
                    content: 'Seçili kayıtları silmek istiyor musunuz?',
                    onOk() {
                        self.axios.get(uri).then(msg => {
                            self.isTableReady = false;
                            self.myGrid.reload().then(() => {
                                let fn = msg.status == 1 ? 'success' : 'error';
                                self.message[fn](msg.statusText);
                                self.isTableReady = true;
                            });
                        });
                    },
                    onCancel() {},
                    cancelText: "Hayır",
                    okText: "Evet",
                });
            },
            onColumnChange() {
                this.gridRefresh(true);
            },
            openSetting(id) {
                this.visibleSettings[id] = true;
            },
            format(p) {
                p = isNaN(parseFloat(p)) ? 0 : parseFloat(p);

                var newP = '0' + p + '';
                newP = newP.indexOf('.') > 0 ? newP + '000' : newP + '.000';
                if (newP.indexOf('.') === 0) {
                    newP = '0' + newP;
                }
                var intVal = parseInt(newP) + '';
                var floatVal = newP.replace(/^.*?\./g, '') + "00"; //0.12345

                var f1 = parseInt(floatVal.replace(/^-?(\d{2})(.*?)$/g, "$1")); //12
                var f2 = parseInt(floatVal.replace(/^-?(\d{2})(.*?)$/g, "$2").replace(/^(\d{2})(.*?)$/g, "$1")); //34

                if (f2 > 49) {
                    f1++;
                }
                if (f1 > 99) {
                    floatVal = "00";
                    intVal = (parseInt(newP) + 1) + '';
                } else {
                    floatVal = f1 > 9 ? f1 : "0" + f1;
                }
                intVal = intVal.replace(/-?(\d{1,3})(\d{3})(\d{3})$/g, '$1.$2.$3');
                intVal = intVal.replace(/-?(\d{1,3})(\d{3})$/g, '$1.$2');
                intVal = intVal.replace(/-?(\d{3})$/g, '$1');
                return intVal + ',' + floatVal;
            },
            update(id, field, val) {
                let formData = new FormData();
                formData.append(field, val);
                formData.append('_method', 'put');
                this.axios.post(this.url.get(this.data.table, 'update', id), formData).then(msg => {
                    this.isTableReady = false;
                    this.myGrid.reload().then(() => {
                        this.isTableReady = true;
                    });
                    if (0 == msg.status) {
                        this.message.error(msg.statusText);
                    }
                });
            },
            comboChange(field, val, id) {
                this.update(id, field, val);
            },
            onTableChange(pagination, filters, sorter) {
                if (sorter.order) {
                    let dir = sorter.order === 'ascend' ? '' : '-';
                    this.myGrid.setParam('sort', dir + sorter.field);
                    // this.myGrid.setParam('dir', sorter.order === 'ascend' ? 'ASC' : 'DESC');
                } else {
                    this.myGrid.setParam('sort', '-id');
                    // this.myGrid.setParam('dir', 'DESC');
                }
                this.myGrid.setParam('page', pagination.current);
                this.myGrid.reload();
            },
            onSelectChange(selectedRowKeys, selectedRows) {
                this.selectedRowKeys = selectedRowKeys;
                this.myGrid.setRows(selectedRows);
            },
            redirectDetail(url, internal = false) {
                return internal ? "/#" + url : url;
            },
            rowDblClick(evt) {
                let attrs = evt.target.closest("tr").attributes || [];
                for (let i = 0; i < attrs.length; i++) {
                    if (attrs[i].name == 'data-row-key') {
                        let index = this.selectedRowKeys.indexOf(attrs[i].value);
                        if (index < 0) {
                            this.selectedRowKeys.push(attrs[i].value);
                        } else {
                            this.selectedRowKeys = this.selectedRowKeys.filter(item => item !== attrs[i].value);
                        }
                    }
                }

                let rows = [];
                for (let x = 0; x < this.selectedRowKeys.length; x++) {
                    let r = this.dataStoreById[this.selectedRowKeys[x]] || {};
                    rows.push(r);
                }

                this.onSelectChange(this.selectedRowKeys, rows);
            },
            gridRefresh(isDataReload = true) {
                this.isTableReady = false;
                let cols = [],
                    sqlSelect = [];

                if (typeof this.data.editable !== 'undefined') {
                    let ed = JSON.parse(JSON.stringify(this.data.editable));
                    ed.table = this.data.table;
                    ed.id = 0;
                    ed.labelCol = this.data.editable.labelCol || { xxl: 9, xl: 9, lg: 9, md: 9, sm: 24, xs: 24 };
                    ed.wrapperCol = this.data.editable.wrapperCol || { xxl: 15, xl: 15, lg: 15, md: 15, sm: 24, xs: 24 };
                    if (typeof ed.callback !== 'function') {
                        ed.callback = (res) => {
                            if (!res.status && res.data.length > 0) {
                                this.formErrors = res.data;
                            } else {
                                this.onResponse(res).then(() => {
                                    this.visibleEditable = false;
                                    this.gridRefresh();
                                });
                            }
                        };
                    }
                    this.editableData = ed;
                }

                if (typeof this.data.isRemove !== 'undefined') {
                    this.isRemove = this.data.isRemove;
                }
                if (typeof this.data.isCheck !== 'undefined') {
                    this.isCheck = this.data.isCheck;
                }
                if (typeof this.data.isAdd !== 'undefined') {
                    this.isAdd = this.data.isAdd;
                }
                if (typeof this.data.isDrawer !== 'undefined') {
                    this.isDrawer = this.data.isDrawer;
                }
                if (typeof this.data.isNav !== 'undefined') {
                    this.isNav = this.data.isNav;
                }

                for (let i = 0; i < this.configNew.length; i++) {
                    let append = {
                        title: this.configNew[i].title || this.configNew[i].text,
                        select: this.configNew[i].select || 'id',
                        dataIndex: this.configNew[i].dataIndex,
                        sorter: this.configNew[i].sortable || false,
                        width: this.configNew[i].width || '20%',
                        align: this.configNew[i].align || 'left',
                        type: this.configNew[i].type || 'input',
                        url: this.configNew[i].url || 'undefinedUrl',
                        renderer: this.configNew[i].renderer || undefined,
                    };

                    this.configNew[i].active = typeof this.configNew[i].active !== 'undefined' ? this.configNew[i].active : true;
                    let ddd = this.configNew[i].display || 'xxl|xl|lg|md|sm|xs';

                    if (ddd.indexOf(this.$store.state.screen) > -1 && this.configNew[i].active) {
                        cols.push(append);
                        sqlSelect.push(this.configNew[i].select || append.dataIndex);
                        this.activeColumns[append.dataIndex] = append.title;
                    }
                }

                if (cols.find(x => x.renderer == 'settingsEditable') == undefined) {
                    cols.push({
                        width: (this.$store.state.screen == 'xs' || this.$store.state.screen == 'sm') ? '15px' : '0px',
                        renderer: 'settingsEditable',
                        sorter: false,
                        align: 'right',
                        className: 'setting-btns'
                    });
                }

                this.columns = cols;

                let params = JSON.parse(JSON.stringify(this.data));
                delete params.config;
                delete params.title;
                delete params.isRemove;
                delete params.isCheck;

                params.select = sqlSelect.join('-');

                this.myGrid.reset();
                this.myGrid.setParam('page', 1);
                for (let key in params) {
                    this.myGrid.setParam(key, params[key]);
                }

                //console.log(this.data);

                this.pagination.pageSize = this.data.limit || this.myGrid.params.limit || 20;

                if (isDataReload) {
                    this.isTableReady = false;
                    this.myGrid.reload().then(rrr => {
                        this.setStore(rrr);
                        this.isTableReady = true;
                    });
                }
            },
            setParam(key, val) { // referans olarak kullanılan bir fonksiyon. örnek : BlockLang.vue içerisinde
                this.myGrid.setParam(key, val);
            },
            reload() { // referans olarak kullanılan bir fonksiyon. örnek : BlockLang.vue içerisinde
                this.isTableReady = false;
                this.myGrid.reload().then(rrr => {
                    this.setStore(rrr);
                    this.isTableReady = true;
                });
            },
        },
        mounted() {
            this.isNew = this.data.isNew || this.data.new || false;
            this.myGrid = this.isNew ? new GridApi() : this.grid;

            this.fetch();
        }
    };
</script>