import { createStore } from "vuex";

let vuexState = {};
try {
    vuexState = JSON.parse(localStorage.getItem('vuex'));
} catch (err) {
    // console.log(err);
}

vuexState = {
    is_logged: false,
    loading: true,
    isLoading: true,
    baseURL: '',
    screen: 'xl',
    width: 500,
    height: 500,
    language: 'tr',
    siteURL: "",
    title: 'T-Soft V5 Paneli',
    logo: '',
    version: '',
    sidebarCollapsed: true,
    darkMode: false,
    isReady : true,
    user: {
        exp_at: 0
    },
    ...vuexState
};

export default createStore({
    plugins: [],
    state: vuexState,
    mutations: {
        update(state, payload) {
            for (var key in payload) {
                state[key] = payload[key];
            }
        }
    },
    actions: {
        update({ commit }, obj) {
            for (var key in obj) {
                this.state[key] = obj[key];
            }
            localStorage.setItem('vuex', JSON.stringify(this.state));
            if(typeof obj.darkMode == 'boolean') {
                if (obj.darkMode === true) {
                    document.documentElement.classList.add('dark');
                } else {
                    document.documentElement.classList.remove('dark');
                }
            }
            return new Promise((resolve) => {
                resolve();
            });
        },
        refresh(){
            this.state.isReady = false;
            setTimeout(()=>{
                this.state.isReady = true;
            }, 10);
        },
        logout({ commit }) {
            let obj = {
                siteURL: '',
                baseURL: '',
                user: {
                    exp_at: 0
                },
                is_logged: false
            };
            for (var key in obj) {
                this.state[key] = obj[key];
            }
            localStorage.setItem('vuex', JSON.stringify(this.state));
            return new Promise(resolve => {
                resolve();
            });
        },
    },
    modules: {}
});