<template>
    <a-breadcrumb class="bg-white dark:bg-gray-800 border border-white dark:border-gray-500 rounded-md flex mb-5">
        <a-breadcrumb-item class="homepage-item" v-if="config.isHome">
            <router-link to="/">
                <font-awesome-icon icon="home" />
            </router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item v-for="(item, i) in config.navbar" v-bind:key="i">
            <router-link :to="item.link" v-html="item.name" v-if="item.link"/>
            <span v-html="item.name" v-else></span>
        </a-breadcrumb-item>
    </a-breadcrumb>
</template>

<script>
export default {
    name: "Navbar",
    components: {},
    props: {
        config: {
            type: Object,
            default: function() {
                return { isHome: true, navbar: [] };
            }
        }
    }
};
</script>