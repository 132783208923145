import { createApp, h } from "vue";

import {
    Affix,
    Alert,
    Avatar,
    Badge,
    Breadcrumb,
    Button,
    Card,
    Carousel,
    ConfigProvider,
    Col,
    Collapse,
    DatePicker,
    Dropdown,
    Drawer,
    Divider,
    Layout,
    List,
    Menu,
    Modal,
    Row,
    Form,
    Checkbox,
    Input,
    InputNumber,
    PageHeader,
    Popconfirm,
    Progress,
    Radio,
    Switch,
    Upload,
    Select,
    Slider,
    Space,
    Steps,
    Spin,
    Table,
    Tabs,
    Tooltip,
    Tree,
    TreeSelect,
    message
} from "ant-design-vue";
import App from "_lib/App.vue";

import router from "./router";
import store from "_lib/store";
import axios from '_lib/plugins/axios';
import VueAxios from "vue-axios";
import { createI18n } from "vue-i18n";

import { LocalApi } from "_lib/common/LocalApi.js";
import { GridApi } from "_lib/common/GridApi.js";
import { OrmApi } from "_lib/common/OrmApi.js";
import { UrlApi } from "_lib/common/UrlApi.js";
import CFormItem from '_lib/components/common/CFormItem.vue';
import Combobox from '_lib/components/common/Combobox.vue';
import MyEditor from '_lib/components/common/MyEditor.vue';
import Navbar from "_lib/components/common/Navbar.vue";
import Editable from "_lib/components/common/Editable.vue";
import EditableInfo from "_lib/components/common/EditableInfo.vue";
import PageTitle from "_lib/components/PageTitle.vue";
import FormFixedSaveBar from "_lib/components/FormFixedSaveBar.vue";

import MyGrid from "@/components/general/MyGrid.vue";

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(fas, fab, far);

import {
    CloseCircleOutlined,
    CheckCircleOutlined,
    CloseOutlined,
    CloudUploadOutlined,
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    ExpandOutlined,
    FormOutlined,
    HomeOutlined,
    LinkOutlined,
    PlusOutlined,
    ReloadOutlined,
    SearchOutlined,
    SaveOutlined,
    SettingOutlined,
    ThunderboltOutlined,
    UnorderedListOutlined,
    UploadOutlined
} from '@ant-design/icons-vue';

/* Dark mode */
if (store.state.darkMode === true) {
    document.documentElement.classList.add('dark');
} else {
    document.documentElement.classList.remove('dark');
}

let recursiveFormDataCreater = function (obj, parent = "", returnVal = null) {
    returnVal = returnVal || {};
    if (typeof obj !== 'object') {
        returnVal[parent] = obj;
    } else {
        for (var key in obj) {
            if (obj[key] === null) {
                returnVal[parent + "[" + key + "]"] = "";
            } else if (typeof obj[key] == 'object') {
                if (typeof obj[key] == 'object' && obj[key].length && obj[key][0]) {
                    for (let i = 0; i < obj[key].length; i++) {
                        returnVal = recursiveFormDataCreater(obj[key][i], parent + "[" + key + "][" + i + "]", returnVal);
                    }
                } else if (obj[key].lastModified) {
                    let indis = parent + "[" + key;
                    indis = indis.replace(/\]\[/g, '|').replace(/\]/g, '|').replace(/\[/g, '|');
                    returnVal[indis] = obj[key];
                } else {
                    returnVal = recursiveFormDataCreater(obj[key], parent + "[" + key + "]", returnVal);
                }
            } else if (typeof obj[key] == 'boolean') {
                returnVal[parent + "[" + key + "]"] = obj[key] ? '1' : '0';
            } else {
                returnVal[parent + "[" + key + "]"] = obj[key];
            }
        }
    }
    return returnVal;
};

const app = createApp(App);
app.config.productionTip = false;
app.config.globalProperties.message = message;
app.config.globalProperties.convertToFormData = function (obj) {
    var fm = new FormData();
    let arr = recursiveFormDataCreater(obj);
    for (let kkk in arr) {
        let newKey = kkk.replace('[', '').replace(']', '').replace(/^\|/g, '');
        fm.append(newKey, arr[kkk]);
    }
    return fm;
};

app.config.globalProperties.diffObject = function (oldArr, newArr) {
    let obj = {};
    for (let key in newArr) {
        if (newArr[key] !== oldArr[key]) {
            obj[key] = newArr[key];
        }
    }
    return obj;
};

app.config.globalProperties.confirm = async function (text) {
    return new Promise((resolve, reject) => {
        Modal.confirm({
            title: "Dikkat !",
            content: h("div", {
                style: "display:inline-block",
                innerHTML: text
            }),
            okText: "Evet",
            cancelText: "Hayır",
            onOk() {
                resolve();
            },
            onCancel() {
                reject();
            },
        });
    });
};

app.config.globalProperties.grid = GridApi();
app.config.globalProperties.orm = OrmApi(app.config.globalProperties);
app.config.globalProperties.url = UrlApi();
app.config.globalProperties.local = LocalApi();

const i18n = createI18n({
    locale: store.state.language || "tr",
    fallbackLocale: ["en", "tr"],
    messages: {
        tr: require("_lib/locales/tr-TR.json"),
        en: require("_lib/locales/en-EN.json"),
        ar: require("_lib/locales/ar-AR.json"),
    },
    loadPath: '/locales/{{lng}}/{{ns}}.json',
});

app.config.globalProperties.onCheckLink = function (link, type, language, tableId = null) {
    let fm = new FormData();
    fm.append('link', link);
    fm.append('xtype', type);
    fm.append('language', language);
    if(tableId != null && typeof tableId == 'number') {fm.append('entityId', tableId);}

    axios.post('/v1/admin/language-vars/check-link', fm).then(app.config.globalProperties.onResponse);
};

app.config.globalProperties.goToDetail = function (link, old = false) {
    if (!old) {
        router.push(link);
    } else {
        const slashPrefix = link.charAt(0) == '/' ? '' : '/';
        return `${store.state.siteURL}${slashPrefix}${link}?token=${store.state.user.bearer}&ref=${window.location.host}`;
    }
};

app.config.globalProperties.onResponse = function (res) {
    let fn = res.status === true || typeof res.status == 'object' ? 'success' : 'error';
    let msg = res.message || res.errorCode || i18n.global.tm("messages."+fn);
    message[fn](msg);


    return new Promise((resolve, reject) => {
        if(res.status){
            resolve();
        }else{
            reject();
        }
    });
};

app.config.globalProperties.timestampToDate = function (timestamp, format) {
    let time = parseInt(timestamp);
    if(Number.isNaN(time) || time < 1000000000) {
        time = 1000000000;
    } else if (time < 9000000000) {
        time = time * 1000;
    }

    const pad  = s => (s < 10) ? '0' + s : s;
    const date = new Date(time);

    format = format.replace(/y+/ig, date.getFullYear());
    format = format.replace(/d+/ig, pad(date.getDate()));
    format = format.replace(/m+/ig, pad(date.getMonth() + 1));
    format = format.replace(/h+/ig, pad(date.getHours()));
    format = format.replace(/i+/ig, pad(date.getMinutes()));
    format = format.replace(/s+/ig, pad(date.getSeconds()));

    return format;
};

app.config.globalProperties.clog = function () {
    if(process.env.NODE_ENV == 'development'){
        console.log("### CLOG ###");
        console.log.apply(null, arguments);
    }
    return "";
};

app.config.globalProperties.substr = function (substr, length=150) {
    const hellip = Array.from(substr).length > length ? '...' : '';
    return `${substr.substr(0, length)}${hellip}`;
};

app.use(i18n);
app.use(store);
app.use(router);
app.use(Affix);
app.use(Alert);
app.use(Avatar);
app.use(Badge);
app.use(Breadcrumb);
app.use(Button);
app.use(Card);
app.use(Carousel);
app.use(Checkbox);
app.use(Col);
app.use(ConfigProvider);
app.use(Collapse);
app.use(DatePicker);
app.use(Dropdown);
app.use(Drawer);
app.use(Divider);
app.use(Form);
app.use(Input);
app.use(InputNumber);
app.use(Layout);
app.use(List);
app.use(Menu);
app.use(Modal);
app.use(PageHeader);
app.use(Popconfirm);
app.use(Progress);
app.use(Row);
app.use(Radio);
app.use(Switch);
app.use(Upload);
app.use(Select);
app.use(Slider);
app.use(Space);
app.use(Spin);
app.use(Steps);
app.use(Table);
app.use(Tabs);
app.use(Tooltip);
app.use(Tree);
app.use(TreeSelect);
app.use(VueAxios, axios);

app.component("CloseCircleOutlined", CloseCircleOutlined);
app.component("CloseOutlined", CloseOutlined);
app.component("CloudUploadOutlined", CloudUploadOutlined);
app.component("CheckCircleOutlined", CheckCircleOutlined);
app.component("CopyOutlined", CopyOutlined);
app.component("DeleteOutlined", DeleteOutlined);
app.component("EditOutlined", EditOutlined);
app.component("ExpandOutlined", ExpandOutlined);
app.component("FormOutlined", FormOutlined);
app.component("HomeOutlined", HomeOutlined);
app.component("LinkOutlined", LinkOutlined);
app.component("PlusOutlined", PlusOutlined);
app.component("ReloadOutlined", ReloadOutlined);
app.component("SaveOutlined", SaveOutlined);
app.component("SearchOutlined", SearchOutlined);
app.component("SettingOutlined", SettingOutlined);
app.component("ThunderboltOutlined", ThunderboltOutlined);
app.component("UnorderedListOutlined", UnorderedListOutlined);
app.component("UploadOutlined", UploadOutlined);
app.component("c-form-item", CFormItem);
app.component("Combobox", Combobox);
app.component("MyEditor", MyEditor);
app.component("MyGrid", MyGrid);
app.component("FormFixedSaveBar", FormFixedSaveBar);
app.component("Navbar", Navbar);
app.component("Editable", Editable);
app.component("EditableInfo", EditableInfo);
app.component("PageTitle", PageTitle);
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount("#app");