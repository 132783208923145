export function LocalApi() {
    const get = function(key, def = false) {
        if (typeof localStorage.getItem(key) === 'object') {
            return def;
        }
        try {
            var obj = JSON.parse(localStorage.getItem(key));
            let now = new Date().getTime();
            if (obj.timemout && obj.timemout > now) {
                return def;
            }
            return typeof obj.value !== 'undefined' ? obj.value : def;
        } catch (ex) {
            return def;
        }
    };
    const set = function(key, val, timeout, def = false) {
        timeout = parseInt(timeout) > 0 ? timeout : 365 * 24 * 3600;
        let obj = { value: val, timeout: new Date().getTime() + (1000 * timeout) };
        localStorage.setItem(key, JSON.stringify(obj));
    };
    return {get, set };
}