import axios from 'axios';
import store from "_lib/store";
import { LocalApi } from "_lib/common/LocalApi.js";

axios.defaults.baseURL = store.state.baseURL;
axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.user.bearer;
axios.defaults.headers.common['Accept-Language'] = store.state.language;

axios.interceptors.request.use(request => {
    request.config = {
        ...(request.config ? request.config : {}),
        start: Date.now(),
        baseURL: store.state.baseURL,
    };

    return request;
});

axios.interceptors.response.use(
    function (response) {
        store.dispatch('update', { loading: false });
        return response.data;
    },
    function (error) {
        if([401].includes(error.response.status) && store.state.is_logged) {
            let obj = {
                siteURL: '',
                baseURL: '',
                user: {
                    exp_at: 0
                },
                is_logged: false
            };
            this.$store.dispatch('update', obj).then(() => {
                LocalApi().set('goBack', window.location.hash.slice(1));
                let uri = process.env.NODE_ENV === 'production' ? '/login' : '/login?demo=1';
                window.location.href = '/#' + uri;
            });
            return;
        }
        store.dispatch('update', { loading: false });
        return Promise.reject(error);
    }
);

export default axios;