<template>
    <a :href="goToDetail('/srv/admin/settings/del-cache/index', true)" class="text-xs font-medium px-2 sm:px-5 h-full flex items-center" :class="cls">
        <font-awesome-icon icon="sync-alt" class="text-lg mr-2" />
        <span>
            <span class="whitespace-nowrap">Ön Bellek</span>&nbsp;
            <br v-if="cls != ''">
            Temizle
        </span>
    </a>
</template>

<script>
export default {
    computed: {
        cls() {
            return this.$store.state.screen == 'xxl' ? 'header-btn-item' : '';
        }
    }
};
</script>