<template>
    <a-layout-header class="bg-white dark:bg-gray-900 p-0 flex justify-between relative pb-12 lg:pb-0">
        <div class="version-bar h-full px-4 py-2 flex justify-center items-center border-r border-gray-100 dark:border-gray-700">
            <img :src="$store.state.darkMode ? darkLogo : logo" alt="T-Soft V5">
            <span class="text-xs text-gray-500 dark:text-white font-medium inline-block pt-6 pl-1" v-if="$store.state.version != ''">{{ $store.state.version }}</span>
        </div>
        <div class="w-full lg:h-full flex justify-end items-center">
            <TSearch />
            <div class="flex h-full" v-if="isBreakPointXXL">
                <TNildesk />
                <TWizard />
                <TClearCache />
                <TViewSite />
            </div>
            <a-dropdown class="header-btn-item h-full flex justify-center items-center" v-else>
                <a class="text-gray-700 hover:text-yellow-500 dark:text-white text-lg px-2 sm:px-5">
                    <font-awesome-icon icon="cogs"/>
                </a>
                <template #overlay>
                    <a-menu>
                        <a-menu-item><TNildesk /></a-menu-item>
                        <a-menu-divider />
                        <a-menu-item><TWizard /></a-menu-item>
                        <a-menu-divider />
                        <a-menu-item><TClearCache /></a-menu-item>
                        <a-menu-divider />
                        <a-menu-item><TViewSite /></a-menu-item>
                        <a-menu-divider v-if="isBreakPointXS" />
                        <TLanguages v-if="isBreakPointXS" />
                        <a-menu-divider v-if="isBreakPointXS" />
                        <div class="flex justify-between items-center" v-if="isBreakPointXS">
                            <span class="text-gray-700 text-xs font-medium px-2">Koyu Tema: </span>
                            <ThemeDarkMode />
                        </div>
                    </a-menu>
                </template>
            </a-dropdown>
            <TMessages v-if="!isHomepage" />
            <TNotifications v-if="!isHomepage" />
            <SwitchToV4 v-if="isHomepage" />
            <TLanguages v-if="!isBreakPointXS" />
            <TUser />
            <ThemeDarkMode v-if="!isBreakPointXS" />
        </div>
    </a-layout-header>
</template>

<script>
import TLogo from '_lib/assets/v5-logo.webp';
import TDarkLogo from '_lib/assets/v5-logo-reverse.webp';

import TSearch from '_lib/components/layouts/parts/Search.vue';
import TViewSite from '_lib/components/layouts/parts/ViewSite.vue';
import TMessages from '_lib/components/layouts/parts/Messages.vue';
import TNotifications from '_lib/components/layouts/parts/Notifications.vue';
import TLanguages from '_lib/components/layouts/parts/Languages.vue';
import TUser from '_lib/components/layouts/parts/User.vue';
import ThemeDarkMode from '_lib/components/layouts/parts/ThemeDarkMode.vue';
import TClearCache from '_lib/components/layouts/parts/ClearCache.vue';
import TWizard from '_lib/components/layouts/parts/Wizard.vue';
import TNildesk from '_lib/components/layouts/parts/Nildesk.vue';
import SwitchToV4 from '_lib/components/layouts/parts/SwitchToV4.vue';

export default {
    components: {
        TSearch, TViewSite, TMessages, TNotifications,
        TLanguages, TUser, ThemeDarkMode, TClearCache,
        TWizard, TNildesk, SwitchToV4
    },
    computed: {
        isBreakPointXXL() {
            return this.$store.state.screen == 'xxl';
        },
        isBreakPointXS() {
            return this.$store.state.screen == 'xs';
        }
    },
    data() {
        return {
            logo: TLogo,
            darkLogo: TDarkLogo,
            isHomepage: this.$route == "Dashboard"
        };
    },
    watch: {
        '$route'(value) {
            this.isHomepage = value.name == "Dashboard";
        }
    }
};
</script>