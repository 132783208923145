<template>
    <a-switch :checked="$store.state.darkMode" @change="setMode" class="mx-2 sm:mx-5 darkModeSwitch"/>
</template>

<script>
export default {
    methods: {
        setMode(value) {
            this.$store.dispatch('update', {
                'darkMode': value
            });
        }
    }
};
</script>