<template>
    <a-config-provider :locale="locale">
        <a-spin class="w-full min-h-full z-[9999]" :spinning="true" tip="Loading..." v-if="$store.state.isLoading && $store.state.loading" />
        <a-layout id="def-layout" class="w-full min-h-full" v-else>
            <a-layout-sider theme="light" v-model:collapsed="collapsed" collapsible class="bg-white dark:bg-gray-900 fixed-sider"
                :class="{ 'fixed-sider-mobile': layoutCls }">
                <router-link to="/" class="bg-gray-50 dark:bg-gray-800 flex items-center justify-center h-15 logo">
                    <img :alt="$store.state.title" :src="$store.state.logo || require('@/assets/logo.png')" class="block">
                </router-link>
                <a-menu class="bg-white dark:bg-gray-900 t-left-menu" v-model:selectedKeys="selectedKeys" v-if="menus.length > 0">
                    <TMenu v-for="(menu, index) in menus" :key="index" :menukey="String(index + 1)" :record="JSON.stringify(menu)" />
                </a-menu>
            </a-layout-sider>
            <a-layout :class="layoutCls">
                <a-layout-content class="bg-blue-50 dark:bg-gray-800 h-full">
                    <THeader />
                    <a-layout-content class="w-full py-5 md:p-7">
                        <router-view />
                    </a-layout-content>
                </a-layout-content>
            </a-layout>
        </a-layout>
    </a-config-provider>
</template>

<script>
    import trTR from 'ant-design-vue/es/locale/tr_TR';
    import { DASHBOARD_MENU_LIST } from '_lib/plugins/dashboardMenu.js';
    import THeader from '_lib/components/layouts/Header.vue';
    import TMenu from '_lib/components/layouts/SidebarMenu.vue';
    export default {
        components: {
            THeader, TMenu
        },
        data() {
            return {
                locale: trTR,
                menus: DASHBOARD_MENU_LIST,
                collapsed: ['xs', 'sm', 'md'].indexOf(this.$store.state.screen) > -1,
                selectedKeys: ['1']
            };
        },
        mounted() {
            setTimeout(() => {
                this.$store.dispatch('update', {
                    isLoading: false,
                    loading: false
                });
            }, 300);
        },
        computed: {
            layoutCls() {
                return ['xs', 'sm', 'md'].indexOf(this.$store.state.screen) == -1 ? (
                    this.collapsed ? 'fixed-sider-enable' : 'fixed-sider-disable'
                ) : 'fixed-sider-enable';
            }
        },
        watch: {
            '$store.state.screen'(value) {
                this.collapsed = ['xs', 'sm', 'md'].indexOf(value) > -1;
            }
        }
    };
</script>