<template>
    <div id="login" class="w-full h-full">
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    name: "LoginLayout"
};
</script>
<style lang="scss">
#login {
    background: transparent url('https://picsum.photos/1920/1080.webp') center center no-repeat;
    background-attachment: fixed;
    background-size: cover;
}
</style>