import axios from '_lib/plugins/axios';
import { ref } from 'vue';
import { message } from "ant-design-vue";
export function GridApi() {
    let column = ref([]);
    let response = ref({
        data: [],
        total: 0
    });
    let params = ref({
        id: 'product1',
        url: '',
        q: '',
        searchOn : '',
        sort: '-id',
        limit: 20,
        page: 1,
        select: '',
        filter: {}
    });
    let selected = ref({
        rows: []
    });
    let visible = ref({
        drawer: false,
    });
    var reset = (newMessage) => {
        params.value = {
            id: params.value.id,
            url: params.value.url,
            q: '',
            searchOn : '',
            sort: '-id',
            limit: 20,
            page: 1,
            select: '',
            filter: {}
        };
        response.value = {
            data: [],
            total: 0
        };
    };
    var setParam = (key, value) => {
        let newParams = JSON.parse(JSON.stringify(params.value));
        newParams[key] = value;
        params.value = newParams;
    };
    var filter = (key, operator, value) => {
        let newParams = JSON.parse(JSON.stringify(params.value));
        if (typeof value == 'undefined' || value === null) {
            delete newParams.filter[key];
        } else {
            newParams.filter[key] = { operator: value };
        }

        params.value = newParams;
    };
    var setAllParams = (parameters) => {
        let newParams = JSON.parse(JSON.stringify(params.value));
        for (let key in parameters) {
            newParams[key] = parameters[key];
        }
        params.value = newParams;
    };
    var getParam = (key, def = null) => {
        return params.value[key] || def;
    };
    var setVisible = (key, value) => {
        let newParams = JSON.parse(JSON.stringify(visible.value));
        newParams[key] = value;
        visible.value = newParams;
    };
    var setColumn = (newColumn) => {
        column.value = newColumn;
    };
    var getColumn = () => {
        return column.value;
    };
    var setRows = (rows) => {
        rows = JSON.parse(JSON.stringify(rows));
        selected.value = { rows: rows };
    };
    var getRows = (field = '') => {
        let arr = [];
        for (let x = 0; x < selected.value.rows.length; x++) {
            arr.push(selected.value.rows[x][field]);
        }
        return arr;
    };
    var reload = async () => {
        let np = JSON.parse(JSON.stringify(params.value));

        delete np.url;

        let ppp = [];
        for (let key in np) {
            if (["url", "id", "from", "editable", "filter", "table", "searchOn", "isAdd"].indexOf(key) < 0) {
                ppp.push(key + "=" + encodeURIComponent(np[key]));
            }
        }

        if(np.q.length>0){
            np.filter.QUERY = {
                q : np.q,
                cols : [ np.searchOn || "id" ]
            };
        }

        if(Object.keys(np.filter).length>0){
            ppp.push("filter=" + encodeURIComponent(JSON.stringify(np.filter)));
        }
        // for (let key in np.filter) {
        //     ppp.push("filter[" + key + "]=" + np.filter[key]);
        // }
        if (params.value.url == "") {
            return new Promise((resolve, reject) => {
                reject({ status: 0, statusText: 'URL BOŞ OLAMAZ' });
            });
        }

        var uri = params.value.url.indexOf('?')>0 ? params.value.url + '&' : params.value.url + '?';
        uri += ppp.join("&");

        let rrr = { data: [], total: 0 };
        await axios.get(uri).then(res => {
            if (res.status === false) {
                message.error(res.message);
            }
            let total = res.paging && res.paging.total ? res.paging.total : 100;
            rrr = { data: JSON.parse(JSON.stringify(res.data)) || [], total: total };
            response.value = rrr;
        });

        return new Promise((resolve, reject) => {
            if (rrr.data) {
                resolve(rrr);
            } else {
                reject(rrr);
            }
        });
    };

    return { setRows, getRows, setAllParams, setParam, getParam, setColumn, getColumn, reload, reset, setVisible, filter, response, params, visible };
}