<style lang="scss">
    .my-code-editor {
        width: 100%;
        position: relative;

        .instance-editor {
            border: 1px solid #000;
            height: auto;
            width: 100%;
            box-sizing: border-box;

            ::-webkit-scrollbar-thumb {
                background: #ff648199 !important;
                border-radius: 10px;
            }

        }
    }
</style>
<template>
    <div class="my-code-editor">
        <div class="instance-editor" :id="id" :style="{ height : height }"></div>
    </div>
</template>
<style lang="scss">
    // @import './node_modules/codemirror/lib/codemirror.css';
</style>
<script>
    // import CodeMirror from 'codemirror';

    import CodeMirror from '_node_modules/codemirror/lib/codemirror.js';

    import "_node_modules/codemirror/lib/codemirror.css";
    import "_node_modules/codemirror/theme/monokai.css";
    // import "_node_modules/codemirror/theme/ayu-dark.css";
    import "_node_modules/codemirror/mode/smarty/smarty.js";
    import "_node_modules/codemirror/mode/sass/sass.js";
    import "_node_modules/codemirror/mode/css/css.js";
    import "_node_modules/codemirror/mode/javascript/javascript.js";
    import "_node_modules/codemirror/mode/htmlmixed/htmlmixed.js";

    import "_node_modules/codemirror/addon/search/search.js";
    import "_node_modules/codemirror/addon/search/searchcursor.js";
    import "_node_modules/codemirror/addon/search/jump-to-line.js";

    import "_node_modules/codemirror/addon/dialog/dialog.js";
    import "_node_modules/codemirror/addon/dialog/dialog.css";

    let myCodeMirror = {};

    CodeMirror.defineExtension("autoFormatRange", function(from, to) {
        var cm = this;
        var outer = cm.getMode(),
            text = cm.getRange(from, to).split("\n");
        var state = CodeMirror.copyState(outer, cm.getTokenAt(from).state);
        var tabSize = cm.getOption("tabSize");

        var out = "",
            lines = 0,
            atSol = from.ch == 0;

        function newline() {
            out += "\n";
            atSol = true;
            ++lines;
        }

        for (var i = 0; i < text.length; ++i) {
            var stream = new CodeMirror.StringStream(text[i], tabSize);
            while (!stream.eol()) {
                var inner = CodeMirror.innerMode(outer, state);
                var style = outer.token(stream, state),
                    cur = stream.current();
                stream.start = stream.pos;
                if (!atSol || /\S/.test(cur)) {
                    out += cur;
                    atSol = false;
                }
                if (!atSol && inner.mode.newlineAfterToken &&
                    inner.mode.newlineAfterToken(style, cur, stream.string.slice(stream.pos) || text[i + 1] || "", inner.state))
                    newline();
            }
            if (!stream.pos && outer.blankLine) outer.blankLine(state);
            if (!atSol) newline();
        }

        cm.operation(function() {
            cm.replaceRange(out, from, to);
            for (var cur = from.line + 1, end = from.line + lines; cur <= end; ++cur)
                cm.indentLine(cur, "smart");
            cm.setSelection(from, cm.getCursor(false));
        });
    });
    // import {
    //     CloseOutlined
    // } from "@ant-design/icons-vue";
    // import Picker from 'vanilla-picker';
    export default {
        name: 'MyCodeEditor',
        components: {
            // CloseOutlined
        },
        props: {
            value: {
                type: String,
                default: function() {
                    return "";
                }
            },
            mode: {
                type: [String, Object],
                default: function() {
                    return "htmlmixed";
                }
            },
            theme: {
                type: [String, Object],
                default: function() {
                    return "monokai";
                }
            },
            height: {
                type: [String, Number],
                default: function() {
                    return "600px";
                }
            },
            id: {
                type: String,
                default: function() {
                    return "myCodeMirror" + new Date().getTime() + Math.round(Math.random() * 10000);
                }
            }
        },
        data() {
            return {
                timingProcess: null,
                config: {
                    value: this.value,
                    mode: this.mode,
                    theme: this.theme
                }
            };
        },
        emits: ['update:value'],
        mounted() {
            this.fetch();
        },
        methods: {
            format() {
                CodeMirror.commands["selectAll"](myCodeMirror[this.id]);
                myCodeMirror[this.id].autoFormatRange(myCodeMirror[this.id].getCursor(true), myCodeMirror[this.id].getCursor(false));
            },
            getValue() {

                // console.log(myCodeMirror[this.id]);
                return myCodeMirror[this.id].doc.getValue();
            },
            fetch() {

                myCodeMirror[this.id] = CodeMirror(document.getElementById(this.id), {
                    value: this.value,
                    mode: this.mode,
                    theme: this.theme,
                    lineNumbers: true
                });
                myCodeMirror[this.id].setSize('100%', this.height);

                myCodeMirror[this.id].on("change", (cm)=>{
                    this.$emit('update:value', cm.getValue());
                });
            }
        }
    };
</script>