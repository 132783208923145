<template>
    <div v-if="isReady" class="w-full">
        <a-select :class="cls" :allowClear="allowClear" @focus="onFocus" :autoComplete="false" v-if="search==1" :mode="mode" :placeholder="placeholder" :filter-option="false" :showSearch="true" v-model:value="value1" @change="onChange" style="width: 100%" @search="handleSearch">
            <a-select-option v-for="(item) in store1" :key="item[textField]" :value="item[valueField1]">
                {{ item[textField1] }}
            </a-select-option>
            <template v-slot:[slotKey]="" v-for="(slot, slotKey) in $slots">
                <slot :name="slotKey"></slot>
            </template>
        </a-select>
        <a-select :class="cls" :allowClear="allowClear" @focus="onFocus" v-if="search!=1" :mode="mode" :placeholder="placeholder" :filter-option="filterOption" option-filter-prop="name" @change="onChange" v-model:value="value1" :showSearch="true" style="width: 100%">
            <a-select-option v-for="(item) in store1" :key="item[textField]" :value="item[valueField1]">
                {{ item[textField1] }}
            </a-select-option>
            <template v-slot:[slotKey] v-for="(slot, slotKey) in $slots">
                <slot :name="slotKey"></slot>
            </template>
        </a-select>
    </div>
</template>
<script>
    // import {
    //   store1
    // } from '../store1/store1';
    // import Panel from "@/components/panel";
    export default {
        name: "TableImage",
        components: {

        },
        props: {
            id: {
                type: [String, Number],
                default: ""
            },
            name: {
                type: String,
                default: "combo"
            },
            limit: {
                type: Number,
                default: 20
            },
            mode: {
                type: String,
                default: null
            },
            search: {
                type: Number,
                default: 0
            },
            placeholder: {
                type: String,
                default: ""
            },
            allowClear: {
                type: Boolean,
                default: true
            },
            table: String,
            extra: {
                type: Array,
                default: () => []
            },
            value: {
                type: [String, Array, Number],
                default: () => undefined
            },
            textField: {
                type: String,
                default: () => "title"
            },
            valueField: {
                type: String,
                default: () => "id"
            },
            callback: {
                type: Function,
                default: function() {

                }
            },
            store: {
                type: [Array, Object],
                default: () => []
            },
            class: {
                type: String,
                    default: () => "test"
            }
        },
        data() {
            return {
                isReady: false,
                textField1: "",
                valueField1: "",
                value1: undefined,
                store1: [],
                timer: null
            };
        },
        emits: ['update:value'],
        mounted() {
            // console.log(this.textField);
            this.fetch();
        },
        computed: {
            cls() {
                return this.class;
            }
        },
        methods: {
            fetch() {
                // console.log('this.value => ', this.value);
                this.textField1 = this.textField + "";
                this.valueField1 = this.valueField + "";

                if (typeof this.value !== "undefined" && this.value !== "" && (this.value !== "0" || this.extra.length > 0 || this.store[this.value])) {
                    this.value1 = this.value;
                }

                if (this.table && this.table.length > 2) {

                    let ids = typeof this.value1 === 'object' ? this.value1.join('-') : this.value1;

                    let params = {
                        q: "",
                        select: this.valueField1 + ',' + this.textField1,
                        perPage: this.limit,
                        sort: this.textField1
                    };
                    if (this.value1 && parseInt(ids) != 0) {
                        params['filter'] = { group_id : { eq: parseInt(ids) } };
                    }

                    this.orm.getResult(this.url.get(this.table, 'list'), params).then(res => {
                        // console.log(res);
                        this.store1 = [...this.extra, ...res];
                        this.isReady = true;
                    });
                } else {
                    let ns = [];
                    if (typeof this.store.length === 'undefined') {
                        let lastStore = [];
                        let append = {};
                        for (let key in this.store) {
                            append = {};
                            append[this.textField1] = this.store[key];
                            append[this.valueField] = key;
                            lastStore.push(append);
                        }
                        ns = lastStore;
                    } else {
                        ns = this.store || [];
                    }
                    this.store1 = ns;
                    this.isReady = true;
                }
            },
            onFocus(e) {
                e.target.setAttribute("autocomplete", "chrome-off");
            },
            filterOption(input, option) {
                return option.key.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0;
            },
            handleSearch(val) {
                if (this.search == 0 || val.trim().length < 1) {
                    return false;
                }
                if (this.timer) {
                    clearTimeout(this.timer);
                }
                this.timer = setTimeout(() => {
                    this.orm.getResult(this.table, { q: val, select: this.valueField1 + ',' + this.textField1 }).then(res => {
                        this.store1 = res;
                        this.isReady = true;
                    });
                }, 250);
            },
            onChange(val) {
                this.$emit('update:value', val);
                this.callback(this.name, val, this.id, this.getName(val));
            },
            getName(key) {
                let fff = this.store1.find(element => element.id === key);
                if (fff) {
                    return fff.name;
                } else {
                    return '';
                }
            }
        },
    };
</script>