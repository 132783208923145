<template>
    <a :href="goToDetail('/srv/admin/ticket/ticket/index', true)" class="text-xs font-medium px-2 sm:px-5 h-full flex items-center" :class="cls">
        <img src="@/assets/nildesk.webp" alt="Nildesk" class="mr-2"/>
        <span>
            <span class="text-yellow-500 whitespace-nowrap">Desteğe mi İhtiyacınız var?</span>&nbsp;
            <br>tsoft.nildesk.com
        </span>
    </a>
</template>

<script>
export default {
    computed: {
        cls() {
            return this.$store.state.screen == 'xxl' ? 'header-btn-item' : '';
        }
    }
};
</script>