<template>
    <div v-if="isReady">
        <ul ref="errorList" class="list-disc pl-4 mb-3" v-show="errors.length > 0">
            <li v-for="err in errors" :key="err.key" class="text-gray-700 text-sm" v-html="$tm(`errorMessages.${err.key}`)"></li>
        </ul>
        <editable-info v-if="!editableData.isLanguage" :data="editableData" @checklink="checkLink($event)"></editable-info>
        <a-tabs v-else 
            :tab-position="['lg', 'xl', 'xxl'].includes($store.state.screen) ? 'right' : 'top'" 
            v-model:activeKey="languageActive" 
            @change="langChange"
        >
            <a-tab-pane :key="lang.code" :tab="lang.name" v-for="lang in languageList">
                <editable-info v-if="lang.code==languageDefault" :data="editableData" @checklink="checkLink($event)"></editable-info>
                <div v-else>
                    <editable-info v-if="languageActive==lang.code && isReadyForLang[lang.code]" :data="recordLang[lang.code]" @checklink="checkLink($event)"></editable-info>
                </div>
            </a-tab-pane>
        </a-tabs>
    </div>
</template>
<script>
    export default {
        name: "Editable",
        emits: ['newdata'],
        props: {
            data: {
                type: Object,
                default: function() {
                    return {
                        id: 0,
                        table: '',
                        isLanguage: true,
                        isReload: false,
                        isDefaultLangDisabled: false,
                        config: [],
                        callback: function() {}
                    };
                }
            },
            errors: {
                type: Array
            }
        },
        data() {
            return {
                id: 0,
                table: '',
                languageList: [],
                isReload: true,
                languageActive: 'tr',
                languageDefault: 'tr',
                editableData: {},
                isReady: false,
                isReadyForLang: {},
                recordLang: {},
                record: {},
                labelCol: { xxl: 6, xl: 6, lg: 6, md: 6, sm: 6, xs: 8 },
                wrapperCol: { xxl: 18, xl: 18, lg: 18, md: 18, sm: 18, xs: 16 }
            };
        },
        mounted() {
            this.fetch();
        },
        methods: {
            fetch() {
                for (var key in this.data) {
                    this.editableData[key] = this.data[key];
                }
                if (typeof this.editableData.isLanguage === 'undefined') {
                    this.editableData.isLanguage = true;
                }
                if (typeof this.editableData.record !== 'undefined') {
                    this.record = this.editableData.record;
                }
                if (this.editableData.id < 1) {
                    this.editableData.isLanguage = false;
                }
                if (!this.editableData.labelCol) {
                    this.editableData.labelCol = this.labelCol;
                }
                if (!this.editableData.wrapperCol) {
                    this.editableData.wrapperCol = this.wrapperCol;
                }
                if (this.editableData.isLanguage && this.editableData.id > 0) {
                    this.axios.get('/v1/admin/language-vars/get-lang-list').then((response) => {
                        this.languageList = [...response.data];
                        this.languageActive = response.message;
                        this.languageDefault = response.message;

                        var newResult = [];
                        if (this.editableData.isDefaultLangDisabled) {
                            for (let i = 0; i < this.languageList.length; i++) {
                                if (this.languageList[i].code == response.active) {
                                    continue;
                                }
                                newResult.push(this.languageList[i]);
                            }
                            response.active = newResult[0].code;
                            this.languageList = newResult;
                        }

                        if (this.editableData.isDefaultLangDisabled) {
                            this.langChange(response.active);
                            this.isReady = true;
                        } else {
                            this.loadRecord();
                        }
                    });
                } else {
                    this.loadRecord();
                }

            },
            loadRecord() {
                if (this.editableData.id > 0) {
                    this.orm.getRow(this.editableData.table, this.editableData.id).then((rrr) => {
                        let row = {...rrr};
                        for (let i = 0; i < this.editableData['config'].length; i++) {
                            let a = this.editableData['config'][i];
                            this.editableData['config'][i].xtype = this.editableData['config'][i].xtype || 'input';
                            if (a.dataIndex) {
                                if ('checkbox' == a.xtype) {
                                    row[a.dataIndex] = 1 === parseInt(rrr[a.dataIndex]);
                                } else if ('datepicker' == a.xtype) {
                                    row[a.dataIndex] = parseInt(rrr[a.dataIndex]);
                                } else {
                                    row[a.dataIndex] = rrr[a.dataIndex];
                                }
                            }
                        }
                        this.editableData.record = row;
                        this.isReady = true;
                        this.$emit("newdata", rrr);
                    });
                } else {
                    let row = this.record;
                    for (let i = 0; i < this.editableData['config'].length; i++) {
                        this.editableData['config'][i].xtype = this.editableData['config'][i].xtype || 'input';
                        let val = row[this.editableData['config'][i].dataIndex] || this.editableData['config'][i].value || "";
                        row[this.editableData['config'][i].dataIndex] = this.editableData['config'][i].xtype == 'checkbox' ? (val === "0" ? false : true) : val;
                    }
                    this.editableData.record = row;
                    this.isReady = true;
                }
            },
            langChange(lang) {
                this.isReadyForLang = {};
                this.recordLang = {};

                let suffix = this.editableData.table + '/' + this.editableData.id + '/' + lang;
                this.axios.get('/v1/admin/language-vars/get-fields/' + suffix).then((response) => {
                    let result = [...response.data];
                    for (let i = 0; i < result.length; i++) {
                        result[i].xtype = this.editableData.xtype || result[i].xtype;
                        result[i].text = this.editableData.label || result[i].text;
                        result[i].text = result[i].text + " (" + lang + ")";
                    }
                    let ed = {
                        id: this.editableData.id,
                        table: this.editableData.table,
                        isReload: true,
                        labelCol: this.editableData.labelCol,
                        wrapperCol: this.editableData.wrapperCol,
                        updateUrl: '/v1/admin/language-vars/save-fields/' + suffix,
                        config: result,
                        callback : (res)=>{
                            this.onResponse(res);
                        }
                    };
                    this.recordLang[lang] = ed;
                    this.isReadyForLang[lang] = true;
                });
            },
            checkLink(record) {
                this.onCheckLink(record.seoLink, this.editableData.table, (this.editableData.isLanguage ? this.languageActive : null), record.id);
            }
        },
        watch: {
            'errors'() {
                this.$refs.errorList.parentElement.parentElement.scrollTop = 0;
            }
        }
    };
</script>