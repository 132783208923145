<template>
    <a href="javascript:;" class="text-xs font-medium px-2 sm:px-5 h-full flex items-center" :class="cls" @click="showModal = true;">
        <font-awesome-icon icon="magic" class="text-lg mr-2" />
        <span>
            <span class="text-yellow-500">Kurulum</span>&nbsp;
            <br v-if="cls != ''">Sihirbazı
        </span>
    </a>
    <a-modal v-model:visible="showModal" :footer="null" title="Çok yakında">Yapım aşamasında&hellip;</a-modal>
</template>

<script>
export default {
    computed: {
        cls() {
            return this.$store.state.screen == 'xxl' ? 'header-btn-item' : '';
        }
    },
    data() {
        return {
            showModal: false
        }
    }
};
</script>