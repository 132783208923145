export const DASHBOARD_MENU_LIST = [
    {
        title: 'Yönetim Paneli',
        icon: 'home',
        url: '/'
    },
    {
        title: 'Ürünler',
        icon: 'tshirt',
        url: '#',
        children: [
            {
                title: 'Ürünler',
                icon: 'tshirt',
                children: [
                    {
                        title: 'Ürünler',
                        url: '/srv/admin/products/products-v2/index',
                        icon: 'tshirt',
                        target: '_blank'
                    },
                    {
                        title: 'Yorumlar',
                        url: '/srv/admin/products/comments/index',
                        icon: 'tshirt',
                        target: '_blank'
                    },
                    {
                        title: 'Tedarikci',
                        url: '/srv/admin/products/suppliers/index',
                        icon: 'tshirt',
                        target: '_blank'
                    },
                    {
                        title: 'Etiketler',
                        url: '/srv/admin/products/tags/index',
                        icon: 'tshirt',
                        target: '_blank'
                    }
                ]
            },
            {
                title: 'Alt Ürün',
                icon: 'tshirt',
                children: [
                    {
                        title: 'Alt Ürünler',
                        url: '/Y/mod/product/SubProduct/index',
                        icon: 'tshirt',
                        target: '_blank'
                    },
                    {
                        title: 'Alt Ürün Grupları',
                        url: '/Y/mod/product/SubProductGroups/index',
                        icon: 'tshirt',
                        target: '_blank'
                    }
                ]
            },
            {
                title: 'Tanıtımlar',
                icon: 'tshirt',
                children: [
                    {
                        title: 'Markalar',
                        url: '/srv/admin/products/brands/index',
                        icon: 'tshirt',
                        target: '_blank'
                    },
                    {
                        title: 'Modeller',
                        url: '/srv/admin/products/models/index',
                        icon: 'tshirt',
                        target: '_blank'
                    },
                    {
                        title: 'Stok Birimleri',
                        url: '/srv/admin/products/definitions/stock-units/index',
                        icon: 'tshirt',
                        target: '_blank'
                    },
                    {
                        title: 'Ürün Kişiselleştirme',
                        url: '/Y/mod/product/Personalization/index',
                        icon: 'tshirt',
                        target: '_blank'
                    }
                ]
            },
            {
                title: 'Alarmlar',
                icon: 'tshirt',
                children: [
                    {
                        title: 'Fiyat Alarm Listesi',
                        url: '/srv/admin/products/alert/price-alarm/index',
                        icon: 'tshirt',
                        target: '_blank'
                    },
                    {
                        title: 'Stok Alarm Listesi',
                        url: '/srv/admin/products/alert/stock-alarm/index',
                        icon: 'tshirt',
                        target: '_blank'
                    }
                ]
            },
            {
                title: 'Toplu İşlemler',
                icon: 'tshirt',
                children: [
                    {
                        title: 'Çoklu Fotograf Yükleme',
                        url: '/Y/mod/product/imageUploadMultiple/form',
                        icon: 'tshirt',
                        target: '_blank'
                    },
                    {
                        title: 'Seo',
                        url: '/Y/Moduller/_Urun/Seo/SeoTopluIslem.php?UstId=24',
                        icon: 'tshirt',
                        target: '_blank'
                    },
                    {
                        title: 'Excel_urun_disa_aktar',
                        url: '/Y/Moduller/_Urun/ExcelUrunGuncelle/ExcelUrunGuncelleDisa.php?UstId=24',
                        icon: 'tshirt',
                        target: '_blank'
                    },
                    {
                        title: 'Excel Urun İçe Aktar',
                        url: '/Y/Moduller/_Urun/ExcelUrunGuncelle/ExcelUrunGuncelleIce.php?UstId=24',
                        icon: 'tshirt',
                        target: '_blank'
                    },
                    {
                        title: 'Excel Alt ürün Dışa Aktar',
                        url: '/Y/Moduller/_Urun/ExcelUrunGuncelle/ExcelAltUrunGuncDisa.php?UstId=24',
                        icon: 'tshirt',
                        target: '_blank'
                    },
                    {
                        title: 'Excel Alt ürün İçe Aktar',
                        url: '/Y/Moduller/_Urun/ExcelUrunGuncelle/ExcelAltUrunGuncIce.php?UstId=24',
                        icon: 'tshirt',
                        target: '_blank'
                    },
                    {
                        title: 'Fiyat',
                        url: '/Y/Moduller/_Urun/Fiyat/FiyatTopluIslem.php?UstId=24',
                        icon: 'tshirt',
                        target: '_blank'
                    },
                    {
                        title: 'Kod Güncelleme Raporları',
                        url: '/srv/admin/products/batch-process/report/index',
                        icon: 'tshirt',
                        target: '_blank'
                    },
                    {
                        title: 'Excel Ürün Sihirbazı',
                        url: '/Y/mod/product/ExcelProductWizard/index',
                        icon: 'tshirt',
                        target: '_blank'
                    }
                ]
            },
            {
                title: 'E-Tedarik',
                icon: 'tshirt',
                children: [
                    {
                        title: 'Aktarım',
                        icon: 'tshirt',
                        url: '/srv/admin/products/e-supplier/transfer/index',
                        target: '_blank'
                    },
                    {
                        title: 'Karşılaştırma',
                        icon: 'tshirt',
                        url: '/Y/Moduller/_Etedarik/Karsilastirma/Karsilastir.php?UstId=24',
                        target: '_blank'
                    }
                ]
            },
            {
                title: 'Site İçi Arama Motoru',
                icon: 'tshirt',
                children: [
                    {
                        title: 'Arama Konsolu',
                        url: '/srv/admin/products/search-console/index',
                        icon: 'tshirt',
                        target: '_blank'
                    },
                    {
                        title: 'Kelime Eşleştirme',
                        url: '/srv/admin/products/search-match/index',
                        icon: 'tshirt',
                        target: '_blank'
                    },
                    {
                        title: 'Ürün Arama Ayarları',
                        url: '/srv/admin/settings/general-settings/settings?settingCategory=1015',
                        icon: 'tshirt',
                        target: '_blank'
                    }
                ]
            }
        ]
    },
    {
        title: 'Siparişler',
        icon: 'shopping-bag',
        children: [
            {
                title: 'Siparişler',
                icon: 'shopping-bag',
                children: [
                    {
                        title: 'Siparişler',
                        icon: 'shopping-bag',
                        url: '/srv/admin/order/order/index',
                        target: '_blank'
                    },
                    {
                        title: 'Siparişler',
                        url: '/srv/admin/order/order/index',
                        icon: 'shopping-bag',
                        target: '_blank'
                    },
                    {
                        title: 'Sipariş Ürünleri',
                        url: '/srv/admin/order/order/products',
                        icon: 'shopping-bag',
                        target: '_blank'
                    },
                    {
                        title: 'Abonelikler',
                        url: '/srv/admin/order/subscribe/index',
                        icon: 'shopping-bag',
                        target: '_blank'
                    },
                    {
                        title: 'Yeni Sipariş Oluştur',
                        url: '/srv/admin/order/order/add-new',
                        icon: 'shopping-bag',
                        target: '_blank'
                    },
                    {
                        title: 'Siparişler',
                        url: '/Y/Moduller/_Siparis/Siparis.php?UstId=55',
                        icon: 'shopping-bag',
                        target: '_blank'
                    },
                    {
                        title: 'Provizyon Raporları',
                        url: '/srv/admin/payment/provision-report-v2/index',
                        icon: 'shopping-bag',
                        target: '_blank'
                    }
                ]
            },
            {
                title: 'İadeler',
                icon: 'shopping-bag',
                url: '/srv/admin/order/returns/index',
                target: '_blank'
            },
            {
                title: 'İade İşlemleri',
                icon: 'shopping-bag',
                url: '/srv/admin/order/refund/refunds',
                target: '_blank'
            },
            {
                title: 'Teslimat Bölgeleri',
                icon: 'shopping-bag',
                url: '/srv/admin/order/cargo-region/index',
                target: '_blank'
            },
            {
                title: 'Alışveriş Listesi',
                icon: 'shopping-bag',
                url: '/Y/Moduller/_Siparis/AlisverisListem/AlisverisListem.php?UstId=55',
                target: '_blank'
            },
            {
                title: 'Mağaza Satışları',
                icon: 'shopping-bag',
                url: '/srv/admin/order/store-sales/index',
                target: '_blank'
            },
            {
                title: 'Kargo Yönetimi',
                icon: 'shopping-bag',
                children: [
                    {
                        title: 'Kargo_yonetimi',
                        url: '/srv/admin/order/cargo-management/index',
                        icon: 'shopping-bag',
                        target: '_blank'
                    },
                    {
                        title: 'Kargo_entegrasyonu',
                        url: '/srv/admin/order/cargo/ready-for-cargo',
                        icon: 'shopping-bag',
                        target: '_blank'
                    },
                    {
                        title: 'Sevkiyat Planları',
                        url: '/srv/admin/order/cargo/services',
                        icon: 'shopping-bag',
                        target: '_blank'
                    }
                ]
            },
            {
                title: 'Yapılandırma',
                icon: 'shopping-bag',
                children: [
                    {
                        title: 'Sipariş Süreçleri',
                        url: '/srv/admin/order/status/index',
                        icon: 'shopping-bag',
                        target: '_blank'
                    },
                    {
                        title: 'Ödeme Seçenekleri',
                        url: '/Y/Moduller/_Odeme/Odeme.php?UstId=55',
                        icon: 'shopping-bag',
                        target: '_blank'
                    },
                    {
                        title: 'Adres Alanları Yönetimi',
                        url: '/srv/admin/order/address-fields/index',
                        icon: 'shopping-bag',
                        target: '_blank'
                    },
                ]
            },
        ]
    },
    {
        title: 'Kampanyalar',
        icon: 'percent',
        children: [
            {
                title: 'Gelişmiş Kampanya Modülü',
                url: '/srv/admin/campaign-v2/campaign/index?UstId=55',
                icon: 'percent',
                target: '_blank'
            },
            {
                title: 'Kampanya Sihirbazi',
                url: '/srv/admin/campaign/campaign-wizard/index',
                icon: 'percent',
                target: '_blank'
            },
            {
                title: 'Kampanya Kodları',
                url: '/srv/admin/campaign-v2/gift-cards-v2/gift-cards-of-campaign/',
                icon: 'percent',
                target: '_blank'
            },
            {
                title: 'Hediye Ürün',
                url: '/Y/Moduller/_Kampanya/Hediye/HediyeKodlari.php?UstId=55',
                icon: 'percent',
                target: '_blank'
            },
            {
                title: 'Hediye Çekleri',
                url: '/srv/admin/campaign-v2/gift-cards/index',
                icon: 'percent',
                target: '_blank'
            },
            {
                title: 'Hediye Çeki Sihirbazı',
                url: '/srv/admin/campaign-v2/gift-cards-v2/index',
                icon: 'percent',
                target: '_blank'
            },
            {
                title: 'Tavsiye Edenler',
                url: '/srv/admin/campaign-v2/recommended/index',
                icon: 'percent',
                target: '_blank'
            },
            {
                title: 'Tavsiye Et İndirim Kazan',
                url: '/Y/Moduller/_Kampanya/TavsiyeAyar/index.php?UstId=55',
                icon: 'percent',
                target: '_blank'
            },
            {
                title: 'Puan Sistemi',
                url: '/srv/admin/campaign-v2/money-points/index',
                icon: 'percent',
                target: '_blank'
            },
            {
                title: 'Alışveriş Listesi',
                url: '/Y/Moduller/_Siparis/AlisverisListem/AlisverisListem.php?UstId=55',
                icon: 'percent',
                target: '_blank'
            },
            {
                title: 'Müşteri Deneyimi Puanlama',
                url: '/Y/mod/Campaign/commentAndPoints/Index?UstId=55',
                icon: 'percent',
                target: '_blank'
            },
            {
                title: 'Üye Ol Para Kazan',
                url: '/Y/mod/Campaign/becomeMemberEarnMoney/Index',
                icon: 'percent',
                target: '_blank'
            },
            {
                title: 'Kampanya Ayarları',
                url: '/srv/admin/settings/general-settings/settings?settingCategory=1006',
                icon: 'percent',
                target: '_blank'
            }
        ]
    },
    {
        title: 'Kategoriler',
        icon: 'grip-vertical',
        children: [
            {
                title: 'Kategori',
                url: '/srv/admin/category/category/index',
                icon: 'grip-vertical',
                target: '_blank'
            },
            {
                title: 'ust_sekme',
                url: '/srv/admin/category/toptabs/index',
                icon: 'grip-vertical',
                target: '_blank'
            },
            {
                title: 'filtreler',
                url: '/Y/mod/Category/Filter/index?UstId=138',
                icon: 'grip-vertical',
                target: '_blank'
            },
            {
                title: 'combines_menu',
                url: '/srv/admin/category/combine/index',
                icon: 'grip-vertical',
                target: '_blank'
            },
            {
                title: 't_soft_connect',
                url: '/srv/admin/category/connect/index',
                icon: 'grip-vertical',
                target: '_blank'
            }
        ]
    },
    {
        title: 'Üyeler',
        icon: 'users',
        children: [
            {
                title: 'Üyeler',
                url: '/srv/admin/customer/customer/index',
                icon: 'users',
                target: '_blank'
            },
            {
                title: 'Üye Şifre Hatırlat',
                url: '/srv/admin/customer/customer/change-password/index',
                icon: 'users',
                target: '_blank'
            },
            {
                title: 'Üye Grupları',
                url: '/srv/admin/customer/group/memberGroup',
                icon: 'users',
                target: '_blank'
            },
            {
                title: 'Üye Bilgi Alan Yönetimi',
                url: '/srv/admin/customer/CustomerInfoManagement/index',
                icon: 'users',
                target: '_blank'
            },
            {
                title: 'Üye Mesajlaşma',
                url: '/Y/mod/Customer/Message/index?UstId=35',
                icon: 'users',
                target: '_blank'
            },
            {
                title: 'Ziyaretçi Defteri',
                url: '/Y/mod/guestBook/guestBook/index?UstId=94',
                icon: 'users',
                target: '_blank'
            },
            {
                title: 'Sepet İçerik',
                url: '/srv/admin/customer/basket/index',
                icon: 'users',
                target: '_blank'
            },
            {
                title: 'Puan',
                icon: 'users',
                children: [
                    {
                        title: 'Puan Hareket',
                        url: '/srv/admin/customer/point/index',
                        icon: 'users',
                        target: '_blank'
                    },
                    {
                        title: 'Puan Bakiye',
                        url: '/srv/admin/customer/point/balance',
                        icon: 'users',
                        target: '_blank'
                    }
                ]
            },
            {
                title: 'Cari',
                icon: 'users',
                children: [
                    {
                        title: 'Cari Hareketler',
                        url: '/srv/admin/customer/current/index',
                        icon: 'users',
                        target: '_blank'
                    },
                    {
                        title: 'Cari Bakiyeler',
                        url: '/srv/admin/customer/current/balance',
                        icon: 'users',
                        target: '_blank'
                    },
                    {
                        title: 'Ödeme Birimleri',
                        url: '/srv/admin/customer/payment/index',
                        icon: 'users',
                        target: '_blank'
                    },
                    {
                        title: 'Kod Güncelleme Raporları',
                        url: '/srv/admin/customer/batch-process/report/index',
                        icon: 'users',
                        target: '_blank'
                    }
                ]
            }
        ]
    },
    {
        title: 'Tasarım',
        icon: 'palette',
        children: [
            {
                title: 'Blok Yönetimi',
                icon: 'palette',
                url: '/page/1'
            },
            // {
            //     title: 'Tema Marketi',
            //     icon: 'paint-brush',
            //     url: '/theme'
            // },
            {
                title: 'Tema Düzenleyici',
                icon: 'palette',
                url: '/theme/v5-mango'
            },
            {
                title: 'Log Yönetimi',
                icon: 'palette',
                url: '/srv/admin/theme/theme-v4/log-index',
                target: '_blank'
            },
            {
                title: 'Site Alt Kısım',
                icon: 'palette',
                children: [
                    {
                        title: 'linkler',
                        url: '/Y/mod/design/siteFooter/Linkler?UstId=339',
                        icon: 'palette',
                        target: '_blank'
                    },
                    {
                        title: 'kategoriler',
                        url: '/Y/mod/design/siteFooter/Kategoriler?UstId=339',
                        icon: 'palette',
                        target: '_blank'
                    },
                    {
                        title: 'sosyal_medya_ikonlari',
                        url: '/Y/mod/design/siteFooter/SosyalMedyaIkonlari?UstId=339',
                        icon: 'palette',
                        target: '_blank'
                    }
                ]
            },
        ]
    },
    {
        title: 'Bölümler',
        icon: 'sitemap',
        children: [
            {
                title: 'Müşteri Hizmetleri',
                icon: 'sitemap',
                children: [
                    {
                        title: 'Müşteri Hizmetleri',
                        url: '/Y/mod/Section/CustomerServices/index?UstId=81',
                        icon: 'sitemap',
                        target: '_blank'
                    },
                    {
                        title: 'Kategoriler',
                        url: '/Y/mod/Section/CustomerServices/categories?UstId=81',
                        icon: 'sitemap',
                        target: '_blank'
                    }
                ]
            },
            {
                title: 'Uygulama Marketi',
                icon: 'sitemap',
                children: [
                    {
                        title: 'Yüklü Uygulamalarım',
                        url: '/srv/admin/homepage/app-store/plugin?f=my',
                        icon: 'sitemap',
                        target: '_blank'
                    },
                    {
                        title: 'Tüm Uygulamalar',
                        url: '/srv/admin/homepage/app-store/plugin',
                        icon: 'sitemap',
                        target: '_blank'
                    }
                ]
            },
            {
                title: 'Destek',
                icon: 'sitemap',
                children: [
                    {
                        title: 'Destek Taleplerim',
                        url: '/srv/admin/ticket/ticket/index',
                        icon: 'sitemap',
                        target: '_blank'
                    }
                ]
            },
            {
                title: 'Mesaj Merkezi',
                icon: 'sitemap',
                children: [
                    {
                        title: 'E-Posta (Dahili)',
                        url: '/srv/admin/section/messages/index',
                        icon: 'sitemap',
                        target: '_blank'
                    },
                    {
                        title: 'SMS',
                        url: '/Y/Moduller/_MesajMerkezi/Sms/?UstId=81',
                        icon: 'sitemap',
                        target: '_blank'
                    }
                ]
            },
            {
                title: 'İletişim',
                icon: 'sitemap',
                children: [
                    {
                        title: 'İletişim Departmanları',
                        url: '/srv/admin/section/contacts-department/index',
                        icon: 'sitemap',
                        target: '_blank'
                    },
                    {
                        title: 'İletiler',
                        url: '/srv/admin/section/contacts-department/posts',
                        icon: 'sitemap',
                        target: '_blank'
                    },
                    {
                        title: 'Mağazalar',
                        url: '/srv/admin/section/store/index',
                        icon: 'sitemap',
                        target: '_blank'
                    }
                ]
            },
            {
                title: 'İçerik Yönetimi',
                icon: 'sitemap',
                children: [
                    {
                        title: 'İçerik Yönetimi',
                        url: '/srv/admin/content-management/content/index',
                        icon: 'sitemap',
                        target: '_blank'
                    },
                    {
                        title: 'Blog Yönetimi',
                        url: '/blog',
                        /*url: '/Y/mod/Blog/Blog/index?UstId=81',*/
                        icon: 'sitemap',
                    },
                    {
                        title: 'Foto Galeri',
                        url: '/Y/mod/photoGallery/photoGallery/index?UstId=81',
                        icon: 'sitemap',
                        target: '_blank'
                    },
                    {
                        title: 'Video Galeri',
                        url: '/Y/mod/videoGallery/videoGallery/index?UstId=81',
                        icon: 'sitemap',
                        target: '_blank'
                    },
                    {
                        title: 'Haberler',
                        url: '/news',
                        /*url: '/srv/admin/content-management/news/index',*/
                        icon: 'sitemap',
                    },
                    {
                        title: 'Anket',
                        url: '/Y/mod/Section/Surveys/index?UstId=81',
                        icon: 'sitemap',
                        target: '_blank'
                    },
                    {
                        title: 'Seo Görsel Optimizasyonu',
                        url: '/srv/admin/content-management/ImageOptimization/Index',
                        icon: 'sitemap',
                        target: '_blank'
                    }
                ]
            },
            {
                title: 'Link Yönetimi',
                icon: 'sitemap',
                children: [
                    {
                        title: 'Link Yönetimi',
                        url: '/srv/admin/link/link-v2/index',
                        icon: 'sitemap',
                        target: '_blank'
                    },
                    {
                        title: '404 Hata Raporları',
                        url: '/Y/mod/Link/Link404NotFound/index?UstId=81',
                        icon: 'sitemap',
                        target: '_blank'
                    },
                    {
                        title: 'Link Tipi Öntanım Ayarları',
                        url: '/srv/admin/link/link-type-settings/index',
                        icon: 'sitemap',
                        target: '_blank'
                    },
                    {
                        title: 'XML Link Yönetimi',
                        url: '/srv/admin/link/xml-links-management/index',
                        icon: 'sitemap',
                        target: '_blank'
                    }
                ]
            },
            {
                title: 'Mobil',
                icon: 'sitemap',
                children: [
                    {
                        title: 'Anlık Bildirimler',
                        url: '/Y/mod/Mobile/Manager/Notifications?UstId=81',
                        icon: 'sitemap',
                        target: '_blank'
                    },
                    {
                        title: 'Mobil Vitrin',
                        url: '/srv/admin/mobile/manager/edit-showcase?UstId=81',
                        icon: 'sitemap',
                        target: '_blank'
                    }
                ]
            },
            {
                title: 'E-İhracat',
                icon: 'sitemap',
                children: [
                    {
                        title: 'Ülke Fiyatları',
                        url: '/srv/admin/section/e-export/index',
                        icon: 'sitemap',
                        target: '_blank'
                    },
                    {
                        title: 'Esnek Fiyat',
                        url: '/conn/admin/product/FlexiblePrice/index',
                        icon: 'sitemap',
                        target: '_blank'
                    },
                    {
                        title: 'IP Adresine Göre Yönlendirme',
                        url: '/srv/admin/section/ip-default-settings/index',
                        icon: 'sitemap',
                        target: '_blank'
                    },
                    {
                        title: 'Gümrük Vergisi',
                        url: '/srv/admin/section/duty-service/index',
                        icon: 'sitemap',
                        target: '_blank'
                    }
                ]
            },
        ]
    },
    {
        title: 'İstatistikler',
        icon: 'chart-line',
        children: [
            {
                title: 'Ziyaretçi İstatistikleri',
                icon: 'chart-line',
                children: [
                    {
                        title: 'Günlük Ziyaretçi İstatistikleri',
                        url: '/srv/admin/homepage/app-store/awstats',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'Günlük Tüm İstatistikler',
                        url: '/srv/admin/homepage/app-store/awstats',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'Anlık Ziyaretçi İstatistikleri',
                        url: '/srv/admin/homepage/app-store/awstats',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'Anlık Ziyaretçi İstatistikleri',
                        url: '/Y/Moduller/_Istatistik/Aylik_Trafik_Istatistik_Index.php?UstId=221',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'Anlık Tüm İstatistikler',
                        url: '/srv/admin/homepage/app-store/awstats',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'Haftalık Ziyaretçi İstatistikleri',
                        url: '/srv/admin/homepage/app-store/awstats',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'Haftalık Tüm İstatistikleri',
                        url: '/srv/admin/homepage/app-store/awstats',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'Saatlik Sayfa İstatistikleri',
                        url: '/srv/admin/homepage/app-store/awstats',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'Saatlik Tam İstatistikler',
                        url: '/srv/admin/homepage/app-store/awstats',
                        icon: 'chart-line',
                        target: '_blank'
                    }
                ]
            },
            {
                title: 'Sipariş İstatistikleri',
                icon: 'chart-line',
                children: [
                    {
                        title: 'Günlük Sipariş İstatistikleri',
                        url: '/srv/admin/statistics/order/daily/index',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'Aylık Sipariş İstatistikleri',
                        url: '/srv/admin/statistics/order/monthly/index',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'On Senelik Sipariş İstatistikleri',
                        url: '/srv/admin/statistics/order/decade/index',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'Bölgesel Sipariş İstatistikleri',
                        url: '/srv/admin/statistics/order/regional/index',
                        icon: 'chart-line',
                        target: '_blank'
                    }
                ]
            },
            {
                title: 'Sipariş İstatistikleri V3',
                icon: 'chart-line',
                children: [
                    {
                        title: 'Günlük Sipariş İstatistikleri',
                        url: '/Y/Moduller/_Istatistik/GunlukSiparis_Istatistik_Index.php?UstId=221',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'Aylık Sipariş İstatistikleri',
                        url: '/Y/mod/Statistics/OrderStatistics/Monthly/index?UstId=221',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'On Senelik Sipariş İstatistikleri',
                        url: '/Y/Moduller/_Istatistik/OnSenelikSiparis_Istatistik_Index.php?UstId=221',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'Bölgesel Sipariş İstatistikleri',
                        url: '/Y/mod/Statistics/OrderStatistics/Regional/index?UstId=221',
                        icon: 'chart-line',
                        target: '_blank'
                    }
                ]
            },
            {
                title: 'Ürün İstatistikleri',
                icon: 'chart-line',
                children: [
                    {
                        title: 'Arama İstatistikleri',
                        url: '/srv/admin/products/search-console/index',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'Genel Ürün İstatistikleri',
                        url: '/srv/admin/statistics/product/product-sales/index',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'Ürün Bazında Aylık Satış İstatistikleri',
                        url: '/srv/admin/statistics/product/monthly-product-sales/index',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'Aylık Satış İstatistikleri',
                        url: '/srv/admin/statistics/product/monthly-sales/index',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'Üyelerin Satın Alma İstatistikleri',
                        url: '/srv/admin/statistics/product/customer-sales/index',
                        icon: 'chart-line',
                        target: '_blank'
                    }
                ]
            },
            {
                title: 'Detaylı İstatistikler',
                icon: 'chart-line',
                children: [
                    {
                        title: 'Excel Rapor Sihirbazı',
                        href: '/Y/mod/product/ExcelReportWizard/index?UstId=221',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'Excel Rapor Sihirbazı',
                        href: '/srv/admin/statistics/order/excel-report-wizard/index',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'Üye Bazında Detaylı Günlük Genel İstatistikler',
                        href: '/Y/Moduller/_Istatistik/DetayliGunlukUye_Genel_Istatistik_Index.php?UstId=221',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'Üye Bazında Detaylı Günlük Sipariş İstatistikler',
                        href: '/Y/Moduller/_Istatistik/DetayliGunlukUye_Istatistik_Index.php?UstId=221',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'Üye Bazında Detaylı Aylık Genel İstatistikler',
                        href: '/Y/Moduller/_Istatistik/DetayliAylikUye_Genel_Istatistik_Index.php?UstId=221',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'Üye Bazında Detaylı Aylık Sipariş İstatistikler',
                        href: '/Y/Moduller/_Istatistik/DetayliAylikUye_Istatistik_Index.php?UstId=221',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'Üye Bazında Detaylı Yıllık Sipariş İstatistikler',
                        href: '/Y/Moduller/_Istatistik/DetayliYillikUye_Genel_Istatistik_Index.php?UstId=221',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'Üye Bazında Detaylı Yıllık Sipariş İstatistikler',
                        href: '/Y/Moduller/_Istatistik/DetayliYillikUye_Istatistik_Index.php?UstId=221',
                        icon: 'chart-line',
                        target: '_blank'
                    }
                ]
            },
            {
                title: 'Diğer Ziyaretçi İstatistikleri',
                icon: 'chart-line',
                children: [
                    {
                        title: 'Tarayıcı İstatistikleri',
                        url: '/srv/admin/homepage/app-store/awstats',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'Aranan Kelime İstatistikleri',
                        url: '/srv/admin/homepage/app-store/awstats',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'Aranan Kelime Grubu İstatistikleri',
                        url: '/srv/admin/homepage/app-store/awstats',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'Hata İstatistikleri',
                        url: '/srv/admin/homepage/app-store/awstats',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'Link Hatası İstatistikleri',
                        url: '/srv/admin/homepage/app-store/awstats',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'Arama Robotu İstatistikleri',
                        url: '/srv/admin/homepage/app-store/awstats',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'işletim Sistemi İstatistikleri',
                        url: '/srv/admin/homepage/app-store/awstats',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'Dosya Tipi İstatistikleri',
                        url: '/srv/admin/homepage/app-store/awstats',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'Ziyaret İstatistikleri',
                        url: '/srv/admin/homepage/app-store/awstats',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'Ülke İstatistikleri',
                        url: '/srv/admin/homepage/app-store/awstats',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'Oturum İstatistikleri',
                        url: '/srv/admin/homepage/app-store/awstats',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'Arama Motoru Yönlendirme İstatistikleri',
                        url: '/srv/admin/homepage/app-store/awstats',
                        icon: 'chart-line',
                        target: '_blank'
                    },
                    {
                        title: 'Yönlendirilen Sayfa İstatistikleri',
                        url: '/srv/admin/homepage/app-store/awstats',
                        icon: 'chart-line',
                        target: '_blank'
                    }
                ]
            },
        ]
    },
    {
        title: 'Ayarlar',
        icon: 'cogs',
        children: [
            {
                title: 'E-Posta',
                icon: 'envelope',
                children: [
                    {
                        title: 'E-Posta Gönderim Ayarları',
                        url: '/srv/admin/settings/email/configurations?option=1',
                        icon: 'envelope',
                        target: '_blank'
                    },
                    {
                        title: 'E-Posta Hesapları',
                        url: '/srv/admin/settings/email/accounts',
                        icon: 'envelope',
                        target: '_blank'
                    },
                    {
                        title: 'Sistem E-Posta Ayarları',
                        url: '/srv/admin/settings/email/sender-accounts',
                        icon: 'envelope',
                        target: '_blank'
                    }
                ]
            },
            {
                title: 'E-Posta ve SMS Şablonları',
                url: '/srv/admin/settings/messages/email#/dashboard',
                icon: 'envelope',
                target: '_blank'
            },
            {
                title: 'Döviz Yönetimi',
                url: '/srv/admin/settings/currency/index',
                icon: 'envelope',
                target: '_blank'
            },
            {
                title: 'İzleme Kodları',
                url: '/srv/admin/settings/tracking-code/index',
                icon: 'envelope',
                target: '_blank'
            },
            {
                title: 'Sosyal Medya Hesapları',
                url: '/srv/admin/settings/social-media/index',
                icon: 'envelope',
                target: '_blank'
            },
            {
                title: 'Yayınlama Durumu',
                url: '/srv/admin/settings/site-offline/index?UstId=13',
                icon: 'envelope',
                target: '_blank'
            },
            {
                title: 'Genel Ayarlar',
                url: '/srv/admin/settings/general-settings/settings',
                icon: 'envelope',
                target: '_blank'
            },
            {
                title: 'Kullanıcılar',
                icon: 'envelope',
                children: [
                    {
                        title: 'Kullanıcı Giriş Raporu',
                        url: '/Y/mod/user/loginReport/ListReport?UstId=13',
                        icon: 'envelope',
                        target: '_blank'
                    },
                    {
                        title: 'Kullanıcı İşlem Raporu',
                        url: '/Y/mod/user/Users/processReport',
                        icon: 'envelope',
                        target: '_blank'
                    },
                    {
                        title: 'Kullanıcı Yetkilendirme',
                        url: '/srv/admin/management/user/authorization',
                        icon: 'envelope',
                        target: '_blank'
                    },
                    {
                        title: 'Kullanıcılar',
                        url: '/Y/mod/user/Users/index?UstId=13',
                        icon: 'envelope',
                        target: '_blank'
                    },
                    {
                        title: 'Kullanıcı Grupları',
                        url: '/Y/mod/user/group/UserGroup?UstId=13',
                        icon: 'envelope',
                        target: '_blank'
                    },
                    {
                        title: 'Web Servis Kullanıcıları',
                        url: '/Y/mod/Settings/User/RestWebService/index?UstId=13',
                        icon: 'envelope',
                        target: '_blank'
                    }
                ]
            },
            {
                title: 'Dil Yönetimi',
                icon: 'envelope',
                children: [
                    {
                        title: 'Dil Seçimi',
                        url: '/srv/admin/settings/language/index?UstId=13',
                        icon: 'envelope',
                        target: '_blank'
                    },
                    {
                        title: 'Dil Dosyası Yönetimi',
                        url: '/srv/admin/settings/language/conf-management?UstId=13',
                        icon: 'envelope',
                        target: '_blank'
                    }
                ]
            },
            {
                title: 'Ön Bellek',
                icon: 'envelope',
                children: [
                    {
                        title: 'Ön Belleği Boşalt',
                        url: '/srv/admin/settings/del-cache/index',
                        icon: 'envelope',
                        target: '_blank'
                    },
                    {
                        title: 'Seçili Ön Belleği Temizle',
                        url: '/Y/mod/Settings/DelCache/byLink?UstId=13',
                        icon: 'envelope',
                        target: '_blank'
                    },
                    {
                        title: 'Ön Bellek Oluşturma',
                        url: '/Y/mod/Settings/DelCache/generate?UstId=13',
                        icon: 'envelope',
                        target: '_blank'
                    },
                    {
                        title: 'Veritabanını Onar',
                        url: '/srv/admin/update/upgrade/migrate?ref=menu',
                        icon: 'envelope',
                        target: '_blank',
                        support: 1
                    },
                    {
                        title: 'Veritabanı Alan Yöneticisi',
                        url: '/srv/admin/settings/db-field-manager/index?ref=menu',
                        icon: 'envelope',
                        target: '_blank',
                        support: 1
                    },
                    {
                        title: 'Proje Notları',
                        url: '/srv/admin/update/notes/index',
                        icon: 'envelope',
                        target: '_blank',
                        support: 0
                    }
                ]
            },
        ]
    },
];