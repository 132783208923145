<template>
    <a-dropdown :trigger="['click']" class="border-r border-gray-100 dark:border-gray-700">
        <a class="text-gray-700 hover:text-yellow-500 dark:text-white text-xs font-medium px-2 sm:px-5 h-full flex items-center" @click.prevent>
            <span class="pr-3 hidden 2xl:inline-block">{{ fullname }}</span>
            <a-avatar>
                <template #icon>
                    <UserOutlined />
                </template>
            </a-avatar>
            <MoreOutlined class="text-lg ml-3" v-if="isBreakPointXS" />
        </a>
        <template #overlay>
            <a-menu>
                <a-menu-item key="0">
                    <router-link to="/account/profile">{{ $tm('profile.personal_info') }}</router-link>
                </a-menu-item>
                <a-menu-item key="1">
                    <router-link to="/account/password">{{ $tm('profile.change_password') }}</router-link>
                </a-menu-item>
                <a-menu-divider />
                <a-menu-item key="2" @click="logout">{{ $tm('auth.logout') }}</a-menu-item>
            </a-menu>
        </template>
    </a-dropdown>
</template>

<script>
    import { UserOutlined, MoreOutlined } from '@ant-design/icons-vue';
    export default {
        components: {
            UserOutlined,
            MoreOutlined
        },
        computed: {
            fullname() {
                return `${this.$store.state.user.name} ${this.$store.state.user.surname}`;
            },
            isBreakPointXS() {
                return this.$store.state.screen != 'xs';
            }
        },
        methods: {
            logout() {

                let obj = {
                    siteURL: '',
                    baseURL: '',
                    user: {
                        exp_at: 0
                    },
                    is_logged: false
                };
                this.$store.dispatch('update', obj).then(() => {
                    let uri = process.env.NODE_ENV === 'production' ? '/login' : '/login?demo=1';
                    this.$router.push(uri);
                });
            }
        }
    };
</script>