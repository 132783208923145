<template>
    <a-menu-item :key="menukey" v-if="!menu.children">
        <router-link :to="menu.url || '#'" v-if="menu.target === undefined">
            <span class="anticon" v-if="menu.icon">
                <font-awesome-icon :icon="menu.icon" />
            </span>
            <span>{{ menu.title }}</span>
        </router-link>
        <a :href="redirect(menu.url)" v-else>
            <span class="anticon" v-if="menu.icon">
                <font-awesome-icon :icon="menu.icon" />
            </span>
            <span>{{ menu.title }}</span>
        </a>
    </a-menu-item>
    <a-sub-menu :key="menukey" v-else>
        <template #icon v-if="menu.icon"><font-awesome-icon :icon="menu.icon" /></template>
        <template #title>{{ menu.title }}</template>
        <template v-for="(sub, subindex) in menu.children" :key="menukey + '-' + subindex">
            <a-menu-item v-if="!sub.children">
                <router-link :to="sub.url || '#'" v-if="sub.target === undefined">
                    <span class="anticon" v-if="sub.icon">
                        <font-awesome-icon :icon="sub.icon" />
                    </span>
                    <span>{{ sub.title }}</span>
                </router-link>
                <a :href="redirect(sub.url)" v-else>
                    <span class="anticon" v-if="sub.icon">
                        <font-awesome-icon :icon="sub.icon" />
                    </span>
                    <span>{{ sub.title }}</span>
                </a>
            </a-menu-item>
            <a-sub-menu :key="menukey + '-' + subindex" v-else>
                <template #icon v-if="menu.icon"><font-awesome-icon :icon="menu.icon" /></template>
                <template #title>{{ sub.title }}</template>
                <template v-for="(child, childindex) in sub.children" :key="menukey + '-' + subindex + '-' + childindex">
                    <a-menu-item>
                        <router-link :to="child.url || '#'" v-if="child.target === undefined">
                            <span class="anticon" v-if="child.icon">
                                <font-awesome-icon :icon="child.icon" />
                            </span>
                            <span>{{ child.title }}</span>
                        </router-link>
                        <a :href="redirect(child.url)" v-else>
                            <span class="anticon" v-if="child.icon">
                                <font-awesome-icon :icon="child.icon" />
                            </span>
                            <span>{{ child.title }}</span>
                        </a>
                    </a-menu-item>
                </template>
            </a-sub-menu>
        </template>
    </a-sub-menu>
</template>

<script>
export default {
    props: [ 'record', 'menukey' ],
    data() {
        return {
            menu: {},
        };
    },
    methods: {
        redirect(url) {
            return `${this.$store.state.siteURL}${url}?token=${this.$store.state.user.bearer}&ref=${window.location.host}`;
        }
    },
    mounted() {
        this.menu = JSON.parse(this.record);
    }
};
</script>