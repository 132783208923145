<style lang="scss">
    .ant-upload.ant-upload-select {
        width: 100%;
    }
</style>
<template>
    <div class="form-input type-input" v-if="isReady">
        <a-row :guttter="[12, 12]" v-for="row in config" v-bind:key="row.dataIndex">
            <a-col :span="24" v-if="!row.dataIndex || row.dataIndex!=='settings'">
                <c-form-item v-if="row.xtype=='input' && checkCondition(row)" :label-col="labelCol" :wrapper-col="wrapperCol" :label="row.text" labelAlign="left">
                    <a-input :placeholder="row.text" block v-model:value="record[row.dataIndex]" />
                </c-form-item>
                <c-form-item v-if="row.xtype=='link' && checkCondition(row)" :label-col="labelCol" :wrapper-col="wrapperCol" :label="row.text" labelAlign="left">
                    <div class="flex">
                        <a-input :placeholder="row.text" block v-model:value="record[row.dataIndex]" class="flex-auto" />
                        <div class="w-25 pl-1">
                            <a-button type="primary" class="rounded-md" block @click="checkLink(record);">CHECK</a-button>
                        </div>
                    </div>
                </c-form-item>
                <input type="hidden" v-else-if="row.xtype=='hidden'" :value="record[row.dataIndex]" />
                <c-form-item v-else-if="row.xtype=='disabled'" :label-col="labelCol" :wrapper-col="wrapperCol" :label="row.text" labelAlign="left">
                    <a-textarea :placeholder="row.text" block v-model:value="record[row.dataIndex]" disabled :auto-size="{ minRows: 1, maxRows: 10 }" />
                </c-form-item>
                <c-form-item v-else-if="row.xtype=='textarea'" :label-col="labelCol" :wrapper-col="wrapperCol" :label="row.text" labelAlign="left">
                    <a-textarea :placeholder="row.text" block v-model:value="record[row.dataIndex]" :auto-size="{ minRows: 2, maxRows: 10 }" />
                </c-form-item>
                <c-form-item v-else-if="row.xtype=='number'" :label-col="labelCol" :wrapper-col="wrapperCol" :label="row.text" labelAlign="left">
                    <a-input-number :placeholder="row.text || 'BBBB'" block v-model:value="record[row.dataIndex]" />
                </c-form-item>
                <c-form-item v-else-if="row.xtype=='checkbox'" :label-col="labelCol" :wrapper-col="wrapperCol" :label="row.text" labelAlign="left">
                    <a-checkbox v-model:checked="record[row.dataIndex]" />
                </c-form-item>
                <c-form-item v-else-if="row.xtype=='combobox' && checkCondition(row)" :label-col="labelCol" :wrapper-col="wrapperCol" :label="row.text" labelAlign="left">
                    <Combobox 
                        v-model:value="record[row.dataIndex]" 
                        :extra="row.extra || []" 
                        :table="row.table" 
                        :store="row.store || []" 
                        :mode="row.mode || null" 
                        :search="row.search || 0" 
                        :limit="row.limit || 20" 
                        :textField="row.textField || undefined " 
                        :valueField="row.valueField || undefined " 
                        :placeholder="row.placeholder || row.text"
                    />
                </c-form-item>
                <c-form-item v-else-if="row.xtype=='ace'" :label-col="labelCol" :wrapper-col="wrapperCol" :label="row.text" labelAlign="left">
                    <div class="w-full">
                        <MyCodeEditor v-model:value="record[row.dataIndex]" :mode="{name: 'smarty', version: 3, baseMode: 'text/html'} " />
                    </div>
                </c-form-item>
                <c-form-item v-else-if="row.xtype=='upload'" :label-col="labelCol" :wrapper-col="wrapperCol" :label="row.text" labelAlign="left">
                    <a-row :gutter="[10,10]">
                        <a-col :span="12">
                            <a-upload class="w-full" v-model:file-list="files[row.dataIndex]" :name="row.dataIndex" :customRequest="selectFile" :showUploadList="false">
                                <a-button block>{{ fTitle[row.dataIndex] || "Resim Seç" }}</a-button>
                            </a-upload>
                        </a-col>
                        <a-col :span="12">
                            <div class="w-full text-center" v-if="checkImage(record, row.dataIndex)">
                                <img style="max-height: 100px;" class="ml-auto mr-auto" :src="checkImage(record, row.dataIndex)" />
                            </div>
                        </a-col>
                    </a-row>
                </c-form-item>
                <c-form-item v-else-if="row.xtype=='editor'" :label-col="24" :wrapper-col="24" :label="row.text" labelAlign="left">
                    <my-editor v-model:value="record[row.dataIndex]"></my-editor>
                </c-form-item>
            </a-col>
        </a-row>
        <a-row :guttter="12" class="mt-3">
            <a-col :span="24">
                <a-button type="primary" class="uppercase" block @click="saveForm">{{ $tm('btn.save') }}</a-button>
            </a-col>
        </a-row>
    </div>
</template>
<script>
    import MyCodeEditor from '_lib/components/common/MyCodeEditor.vue';
    export default {
        components: { MyCodeEditor },
        props: {
            data: Object
        },
        data() {
            return {
                isReady: false,
                title: '',
                table: '',
                updateUrl: '',
                id: 0,
                config: {},
                recordOrj: {},
                record: {},
                labelCol: {},
                wrapperCol: {},
                isReload: true,
                files: {},
                fTitle: {},
                callback: function() {}
            };
        },
        mounted() {
            this.fetch();
        },
        methods: {
            checkCondition(row) {
                let success = true;
                if (row.condition) {
                    if (typeof row.condition == "function") {
                        return row.condition(this.record);
                    } else if (row.condition.key && row.condition.value) {
                        return this.checkConditionChild(row.condition);
                    } else if (row.condition.length && row.condition.length > 0) {
                        for (var i = 0; i < row.condition.length; i++) {
                            success = success && this.checkConditionChild(row.condition[i]);
                        }
                    }
                }
                return success;
            },
            checkConditionChild(con) {
                let k = con.key;
                let v = con.value;
                return this.record[k] == v ? true : false;
            },
            fetch() {
                for (let key in this.data) {
                    this[key] = this.data[key];
                }
                for (let i = 0; i < this.config.length; i++) {
                    let c = this.config[i];
                    if (typeof c.value !== 'undefined' && !this.record[c.dataIndex]) {
                        this.record[c.dataIndex] = c.value;
                    }
                }
                this.recordOrj = { ...this.record };
                this.isReady = true;
            },
            checkImage(record, dataIndex) {
                let inx = dataIndex + "Path";
                let val = record[inx] || record[dataIndex] || "";
                if (val == "") {
                    return false;
                }
                return /http:/ig.test(val) === false ? this.$store.state.siteURL + "/" + val : val;
            },
            selectFile(info) {
                if (!info.file || !info.file.name) {
                    return false;
                }
                this.fTitle[info.filename] = info.file.name;
                return true;
            },
            saveForm() {
                let showNotify = typeof this.data.callback === 'undefined';
                let params = JSON.parse(JSON.stringify(this.record));
                for (let i = 0; i < this.config.length; i++) {
                    if (["input", "number"].indexOf(this.config[i].xtype) > -1) {
                        continue;
                    }
                    let dataIndex = this.config[i].dataIndex;
                    if (this.config[i].xtype == 'checkbox') {
                        params[dataIndex] = params[dataIndex] ? 1 : 0;
                    } else if (this.config[i].xtype == 'upload' && this.files[dataIndex] && this.files[dataIndex].length > 0) {
                        params[dataIndex] = this.files[dataIndex][0].originFileObj;
                    }
                }

                const diffData = this.diffObject(this.recordOrj, params);
                if (diffData.length < 1) {
                    return false;
                }

                diffData.language ??= this.$store.state.language;
                diffData._method = "put";
                if (this.updateUrl.length > 3) {
                    this.axios.post(this.updateUrl, this.convertToFormData(diffData)).then((res) => {
                        this.callback(res, diffData);
                    }).catch((error) => {
                        this.callback(error, diffData);
                    });
                } else {
                    this.orm.save(this.table, this.id, diffData, showNotify).then((res) => {
                        if (this.isReload) {
                            this.grid.reload();
                        }
                        this.callback(res, diffData);
                    }).catch((error) => {
                        this.callback(error, diffData);
                    });
                }
            },
            checkLink(link) {
                this.$emit('checklink', link);
            }
        }
    };
</script>