<template>
    <a-config-provider :locale="locale" v-if="$store.state.isReady">
        <TemplateWrapper v-if="isLogged" />
        <Login v-else />
    </a-config-provider>
</template>

<script>
    import TemplateWrapper from "_lib/components/layouts/TemplateWrapper.vue";
    import Login from "@/components/layouts/Login.vue";

    import trTR from 'ant-design-vue/es/locale/tr_TR';
    import enGB from 'ant-design-vue/es/locale/en_GB';
    import arEG from 'ant-design-vue/es/locale/ar_EG';
    import deDE from 'ant-design-vue/es/locale/de_DE';

    export default {
        name: "App1",
        components: {
            TemplateWrapper,
            Login,
        },
        beforeCreate() {

            // this.$store.dispatch("update", {
            //     is_logged: this.$store.state.is_logged,
            //     baseURL: this.$store.state.baseURL,
            // });
            // this.axios.defaults.baseURL = this.$store.state.baseURL;

        },
        mounted() {
            let triggerResize = () => {
                var screen = 'xs';
                let width = window.innerWidth;
                if (width >= 1640) {
                    screen = 'xxl';
                } else if (width >= 1200) {
                    screen = 'xl';
                } else if (width >= 992) {
                    screen = 'lg';
                } else if (width >= 768) {
                    screen = 'md';
                } else if (width >= 576) {
                    screen = 'sm';
                }
                this.$store.dispatch("update", {
                    screen: screen
                });
            };
            triggerResize();
            window.onresize = triggerResize;
        },
        computed: {
            isLogged() {
                return this.$store.state.is_logged;
            },
            locale() {
                if (this.$store.state.language == 'tr') {
                    return trTR;
                } else if (this.$store.state.language == 'en') {
                    return enGB;
                } else if (this.$store.state.language == 'ar') {
                    return arEG;
                } else if (this.$store.state.language == 'de') {
                    return deDE;
                }
                return trTR;
            }
        },
        data() {
            return {
                collapsed: true,
                locale1: trTR
            };
        }
    };
</script>

<style lang="scss">
    @import "./assets/main.scss";
</style>