<template>
    <div v-if="isReady">
        <tinymce 
            @init="visibleAlertEl('none', 'init')" 
            @beforeExecCommand="visibleAlertEl('none', 'beforeExecCommand')" 
            @beforeGetContent="visibleAlertEl('none', 'beforeGetContent')" 
            @focusOut="visibleAlertEl('block', 'focusout')" 
            @focusIn="visibleAlertEl('none','focusIn')" 
            v-model="data" 
            apiKey="no-api-key" 
            document.domain 
            :init="config">
        </tinymce>
    </div>
</template>
<script>
    import tinymce from "@tinymce/tinymce-vue";
    export default {
        name: 'MyEditor',
        components: { tinymce },
        props: {
            value: {
                type: String,
                default: function() {
                    return "";
                }
            },
            id: {
                type: String,
                default: function() {
                    return "tinyEditor" + Math.round(Math.random() * 100000);
                }
            }
        },
        emits: ['update:value'],
        data() {
            return {
                isReady: false,
                data: "",
                config: {
                    filemanager_title: this.$tm('block.tpl'),
                    file_picker_callback: this.elFinderBrowser,
                    images_upload_credentials: true,
                    strict_loading_mode: true,
                    relative_urls: true,
                    referrer_policy: 'unsafe-url',
                    id: this.id,
                    document_base_url: this.$store.state.siteUrl,
                    height: 500,
                    menubar: true,
                    plugins: [
                        'advlist autolink lists link image imagetools charmap',
                        'searchreplace visualblocks code fullscreen',
                        'print preview anchor insertdatetime media',
                        'paste code help wordcount table code',
                    ],
                    toolbar: 'image | undo redo | formatselect | bold italic | \
                    alignleft aligncenter alignright | \
                    bullist numlist outdent indent | help',
                }
            };
        },
        watch: {
            data: {
                handler: function(val) {
                    this.$emit('update:value', val);
                }
            },
        },
        mounted() {
            this.fetch();
        },
        methods: {
            fetch() {
                this.data = this.value;
                this.isReady = true;
            },
            visibleAlertEl(display = "none", fn) {
                let divArr = document.querySelectorAll('[role="alert"]');
                for (let i = 0; i < divArr.length; i++) {
                    divArr[i].parentElement.style.display = display;
                }
            },
            elFinderBrowser(callback, value, meta) {
                window.tinymce.activeEditor.windowManager.oninsert = (file, fm) => {
                    var url, info;
                    url = fm.convAbsUrl(file.url);
                    url = url.replace(/^https?:/gi, "");
                    info = file.name + " (" + fm.formatSize(file.size) + ")";
                    if (meta.filetype == "file") {
                        callback(url, {
                            text: info,
                            label: info,
                        });
                    }
                    if (meta.filetype == "image") {
                        callback(url, {
                            alt: info,
                        });
                    }
                    if (meta.filetype == "media") {
                        callback(url);
                    }
                };

                window.tinymce.activeEditor.windowManager.openUrl({
                    url: "/elfinder/elfinder-tinymce.html",
                    title: "Dosya Yonet",
                    width: 900,
                    height: 450,
                    resizable: "yes",
                });

                this.visibleAlertEl("block");
                return false;
            }
        },
    };
</script>