<template>
    <div class="mt-3">
        <a-row :class="cls" :gutter="gutter" v-if="labelCol.xxl">
            <a-col 
                :xxl="labelCol.xxl" 
                :xl="labelCol.xl" 
                :lg="labelCol.lg" 
                :md="labelCol.md" 
                :sm="labelCol.sm" 
                :xs="labelCol.xs" 
                :class="labelAlign == 'left' ? 'text-left' : 'text-right'"
                class="text-gray-700 dark:text-gray-50"
            >{{ label }}</a-col>
            <a-col :xxl="wrapperCol.xxl" :xl="wrapperCol.xl" :lg="wrapperCol.lg" :md="wrapperCol.md" :sm="wrapperCol.sm" :xs="wrapperCol.xs">
                <slot></slot>
            </a-col>
        </a-row>
        <a-row :class="cls" :gutter="gutter" v-else>
            <a-col :span="labelCol" :class="labelAlign == 'left' ? 'text-left' : 'text-right'" class="text-gray-700 dark:text-gray-50">{{ label }}</a-col>
            <a-col :span="wrapperCol">
                <slot></slot>
            </a-col>
        </a-row>
    </div>
</template>
<script>
    export default {
        name: 'CFormItem',
        components: {},
        props: {
            gutter: {
                type: [Array, Number],
                default: function() {
                    return [16, 16];
                }
            },
            class: {
                type: String,
                    default: function() {
                        return '';
                    }
            },
            label: {
                type: String,
                default: function() {
                    return '';
                }
            },
            labelAlign: {
                type: String,
                default: function() {
                    return 'right';
                }
            },
            labelCol: {
                type: [Object, Number],
                default: function() {
                    return { xxl: 8, xl: 8, lg: 8, md: 8, sm: 24, xs: 24 };
                }
            },
            wrapperCol: {
                type: [Object, Number],
                default: function() {
                    return { xxl: 16, xl: 16, lg: 16, md: 16, sm: 24, xs: 24 };
                }
            }
        },
        data() {
            return {
                cls: ""
            };
        },
        mounted() {
            this.fetch();
        },
        methods: {
            fetch() {
                this.cls = this.class || "";
            }
        }
    };
</script>