<template>
    <div class="bg-white dark:bg-gray-700 w-full min-h-[2rem] shadow-md fixed top-0 left-0 z-30 flex items-center justify-between p-3">
        <img :src="$store.state.darkMode ? darkLogo : logo" alt="T-Soft V5" class="h-7">
        <div class="font-bold text-gray-700 dark:text-gray-50 text-lg">{{ $tm('form.unsaved_changes') }}</div>
        <div class="flex items-center justify-end">
            <a-button @click.prevent="visibleDestroyModal = true">{{ $tm('common.cancel') }}</a-button>
            <a-button type="primary" @click.prevent="$emit('save')" class="ml-2">{{ $tm('common.save') }}</a-button>
        </div>
        <a-modal v-model:visible="visibleDestroyModal" 
            :title="$tm('form.remove_unsaved_changes')" 
            @ok="confirmDestroy"
            :okText="$tm('form.remove_changes')"
            :okButtonProps="{
                danger: true
            }"
            :cancelText="$tm('form.continue_editing')"
        >
            <p v-html="$tm('form.unsaved_changes_msg')"></p>
        </a-modal>
    </div>
</template>

<script>
import TLogo from '_lib/assets/v5-logo.webp';
import TDarkLogo from '_lib/assets/v5-logo-reverse.webp';
export default {
    data() {
        return {
            logo: TLogo,
            darkLogo: TDarkLogo,
            visibleDestroyModal: false
        };
    },
    methods: {
        confirmDestroy() {
            this.$emit('destroy');
            this.visibleDestroyModal = false;
        }
    },
    mounted() {
        // window.onbeforeunload = (e) => {
        //     e.preventDefault();
        //     e.returnValue = '';
        // };
    }
};
</script>