<template>
    <h1 class="bg-white dark:bg-gray-900 rounded-md shadow p-2 md:p-5 mb-3 text-yellow-500 text-sm md:text-xl">
        <font-awesome-icon :icon="icon" class="mr-2" v-if="icon != ''"/>
        {{ title }}
    </h1>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: ''
            },
            icon: {
                type: String,
                default: ''
            }
        },
    };
</script>