export function UrlApi(app) {
    let uriList = {
        "blog__post": {
            list: '/v1/admin/blog-post/list',
            record: '/v1/admin/blog-post/record/:id',
            update: '/v1/admin/blog-post/update/:id',
            insert: '/v1/admin/blog-post/insert',
            delete: '/v1/admin/blog-post/delete/:id',
        },
        // "blog-category": {
        //     list: '/v1/admin/blog-category/list',
        //     record: '/v1/admin/blog-category/record/:id',
        //     update: '/v1/admin/blog-category/update/:id',
        //     insert: '/v1/admin/blog-category/insert',
        //     delete: '/v1/admin/blog-category/delete',
        // },
    };
    let get = function(table, type, id = 0) {
        if (['list', 'record', 'update', 'insert', 'delete'].indexOf(type) < 0) {
            app.message.error("Sadece list/record/update/insert/delete servislerine erişebilirsiniz. (" + type + ")");
            return "";
        }

        let parameters = "";
        if(table.indexOf(':')>0){
            let arr =  table.split(':');
            let fnd = arr[0].replaceAll('-','\\-')+':';
            parameters= "/"+table.replace(new RegExp(fnd, "ig"),'');
            parameters = parameters.replaceAll(':','/');
            table = arr[0];
        }

        var defaultUrl = {
            list: '/v1/admin/' + table + '/list'+parameters,
            record: '/v1/admin/' + table + '/record/:id'+parameters,
            update: '/v1/admin/' + table + '/update/:id'+parameters,
            insert: '/v1/admin/' + table + '/insert'+parameters,
            delete: '/v1/admin/' + table + '/delete/:id'+parameters,
        };

        return uriList[table] && uriList[table][type] ? uriList[table][type].replace(":id", id) : defaultUrl[type].replace(":id", id);
    };
    return { get };
}