<template>
    <a-dropdown :trigger="['click']" class="header-btn-item">
        <a class="text-gray-700 hover:text-yellow-500 dark:text-white text-xs font-medium px-5 h-full hidden sm:flex sm:items-center" @click.prevent>
            <font-awesome-icon icon="bell" class="text-xl"/>
            <a-badge count="0" :offset="[2, 13]">
                <a href="#" class="head-example" />
            </a-badge>
        </a>
        <!-- <template #overlay>
            <a-menu>
                <a-menu-item key="0">Lorem, ipsum dolor sit amet</a-menu-item>
                <a-menu-item key="1">Lorem, ipsum dolor sit amet</a-menu-item>
                <a-menu-item key="2">Lorem, ipsum dolor sit amet</a-menu-item>
                <a-menu-item key="3">Lorem, ipsum dolor sit amet</a-menu-item>
                <a-menu-item key="4">Lorem, ipsum dolor sit amet</a-menu-item>
            </a-menu>
        </template> -->
    </a-dropdown>
</template>