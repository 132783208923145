import axios from '_lib/plugins/axios';
import { message } from "ant-design-vue";

export function OrmApi(app) {
    const update = async(table, table_id, params, showNotify = true) => {
        table_id = parseInt(table_id);
        if (parseInt(table_id) < 1 || isNaN(table_id)) {
            return new Promise((resolve, reject) => {
                reject({ status: true, message:  "ID bilgisi 0dan küçük olamaz !" });
            });
        }
        return save(table, table_id, params, showNotify);
    };

    const remove = async(table, table_id, showNotify = true) => {
        table_id = parseInt(table_id);
        if (parseInt(table_id) < 1 || isNaN(table_id)) {
            return new Promise((resolve, reject) => {
                reject({ status: true, message:  "ID bilgisi 0dan küçük olamaz !" });
            });
        }

        let uri = "/admin-api/orm/orm/delete/" + table + "/" + table_id;
        let rrr = { status: 1, message:  "Başarıyla Kaydedildi" };

        await axios.get(uri).then(response => {
            rrr = response;
            if (showNotify) {
                if (1 === parseInt(rrr.status)) {
                    message.success(rrr.statusText);
                } else {
                    message.error(rrr.statusText);
                }
            }
        });
        return new Promise((resolve, reject) => {
            if (1 === parseInt(rrr.status)) {
                resolve(rrr);
            } else {
                reject(rrr);
            }
        });
    };

    const save = async(table, table_id, params, showNotify = true) => {
        let xtype = parseInt(table_id)>0 ? 'update' : 'insert';
        let uri = app.url.get(table, xtype, table_id);
        let fm = app.convertToFormData(params);
        let rrr = { status: true, message:  "Başarıyla Kaydedildi" };

        await axios({
            method: xtype == 'update' ? 'put' : 'post',
            url: uri,
            data: fm,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            rrr = response;
            if (showNotify) {
                rrr.status = typeof rrr.status == 'boolean' ? rrr.status : Boolean(rrr.status?.status);
                app.onResponse(rrr);
            }
        }).catch((err)=>{
            rrr = err.response?.data;
            if(err.response && showNotify){
                app.onResponse(err.response.data);
            }
        });

        return new Promise((resolve, reject) => {
            if (true === rrr.status) {
                resolve(rrr);
            } else {
                reject(rrr);
            }
        });
    };

    const getRow = async(table, table_id) => {
        table_id = parseInt(table_id);
        if (isNaN(table_id)) {
            return new Promise((reject) => {
                reject({ id: 0, name: "ID bilgisi 0dan küçük olamaz !" });
            });
        }

        let rrr = { id: 0 };
        let uri = app.url.get(table, "record", table_id);
        await axios.get(uri).then(response => {
            rrr = response.data[0] || {};

        });

        return new Promise((resolve, reject) => {
            if (rrr.id && 0 !== parseInt(rrr.id)) {
                resolve(rrr);
            } else {
                reject(rrr);
            }
        });
    };

    // let queryParams = ref({
    //     id: 'product1',
    //     url: '',
    //     q: '',
    //     sort: 'id',
    //     dir: 'DESC',
    //     searchOn: '',
    //     perPage: 20,
    //     currentPage: 1,
    //     select: '',
    //     filter: {}
    // });

    // const filter = (field, condition, value)=>{
    // };

    const getResult = async(table, params = { limit: 100, select: "id,name", dir: "ASC" }) => {

        let pArr = [];

        // console.log(params);

        for (let key in params) {
            if('filter'==key){
                pArr.push(key + "=" + encodeURIComponent(JSON.stringify(params[key])));
            }else{
                pArr.push(key + "=" + params[key]);
            }
        }
        ///admin/categories/category/get-list/0
        // console.log(pArr);

        let rrr = [];
        let uri = table.indexOf("/") > -1 ? table : app.url.get(table, "list");
        await axios.get(uri + "?" + pArr.join("&")).then(response => {
            rrr = response.data;
        });
        return new Promise((resolve, reject) => {
            if (0 !== parseInt(rrr.id)) {
                resolve(rrr);
            } else {
                reject([]);
            }
        });
    };


    const getObject = async(table, params = { limit: 200, select: "id,name", sort: "id", dir: "ASC" }) => {
        let rrr = [];

        let key = params.key || "id";
        let label = params.label || "name";
        params.select = key + "," + label;
        await getResult(table, params).then(result => {
            rrr = result;
        });

        return new Promise((resolve, reject) => {
            if (rrr.length > 0) {
                let obj = {};
                for (let i = 0; i < rrr.length; i++) {
                    obj[rrr[i][key] + ""] = rrr[i][label];
                }

                // console.log(key);
                // console.log(label);
                // console.log(rrr);
                // console.log(obj);

                resolve(obj);
            } else {
                reject({});
            }
        });
    };

    return { update, save, remove, getRow, getResult, getObject };

}