import { createRouter, createWebHashHistory } from "vue-router";
import store from "_lib/store/index.js";
import axios from '_lib/plugins/axios';
import { LocalApi } from "_lib/common/LocalApi.js";

const routes = [{
        name: "Dashboard",
        path: "/",
        component: () => import ("../views/Home.vue"),
    },
    {
        path: "/page/:id",
        name: "Page",
        props: true,
        component: () => import ("../views/Page"),
    },
    {
        path: "/theme",
        name: "ThemeList",
        component: () => import ("../views/theme/List.vue"),
    },
    {
        path: "/theme/:id",
        name: "ThemeEdit",
        component: () => import ("../views/theme/Edit.vue"),
    },
    {
        path: "/account",
        name: "Account",
        component: () => import ("../views/account/Account"),
        children: [
            {
                path: "profile",
                name: "MyProfile",
                component: () => import ("../views/account/Profile"),
            },
            {
                path: "password",
                name: "ChangePassword",
                component: () => import ("../views/account/Password"),
            }
        ]
    },
    {
        path: "/product",
        // component: () => import ("../views/product/Index"),
    },
    {
        path: "/brand",
        component: () => import ("../views/product/BrandIndex")
    },
    {
        path: "/model",
        component: () => import ("../views/product/ModelIndex")
    },
    {
        path: "/category",
        component: () => import ("../views/category/CategoryIndex")
    },
    {
        path: "/menu",
        component: () => import ("../views/category/MenuIndex")
    },
    {
        path: "/menu/:id",
        props: true,
        component: () => import ("../views/category/MenuDetail")
    },
    {
        path: "/blog",
        name: "Blog",
        component: () =>
            import ("../views/blog/BlogPost"),
    },
    {
        path: "/setting/language",
        name: "LanguageList",
        component: () =>
            import ("../views/setting/LanguageList"),
    },
    {
        path: "/login",
        name: "Login",
        props: true,
        component: () =>
            import ("../views/Login"),
    },
    {
        path: "/change-password/:token/:key?",
        name: "ForgotPassword",
        props: true,
        component: () =>
            import ("../views/ForgotPassword"),
    },
    {
        path: "/block-setting/:id",
        name: "Management",
        props: true,
        component: () =>
            import ("../views/Management"),
    },
    {
        path: "/blog",
        // props: true,
        component: () =>
            import ("../views/blog/BlogPost"),
    },
    {
        path: "/blog/category",
        props: true,
        component: () =>
            import ("../views/blog/BlogCategory"),
    },
    {
        path: "/news",
        name: "News",
        component: () =>
            import ("../views/modules/News"),
    }
];

const router = createRouter({
    history: createWebHashHistory('configure-admin'),
    linkActiveClass: 'active-menu',
    routes: routes
});

router.beforeEach((to, from, next) => {
    document.title = `${store.state.siteURL.replace('https://', '').replace('http://')} :: ${store.state.title}`;
    const pageControll = ["Login", "ForgotPassword"];
    if (store.state.is_logged !== true) {
        const myURL = new URLSearchParams(window.location.href.split('#/')[1]);
        if(myURL.get('token') !== null && myURL.get('domain') !== null) {
            let wwwCtrl = myURL.get('domain').replace('www.', '');
            wwwCtrl = wwwCtrl.replace(/^https?:\/\//gi, '').replace(/\/$/g, '');
            let exp = wwwCtrl.replace(/\.tr$/gi, '').split('.');
            if (exp.length < 3) {
                wwwCtrl = 'www.' + wwwCtrl;
            }
            axios({
                url: `https://${wwwCtrl}/api/v1/admin/authentication/me`,
                method: 'post',
                headers: {
                    'Authorization': `Bearer ${myURL.get('token')}`
                }
            }).then(response => {
                store.state.user.bearer = myURL.get('token');
                const updateUser = {
                    siteURL: 'https://' + wwwCtrl,
                    baseURL: 'https://' + wwwCtrl + '/api',
                    is_logged: true,
                    user: { ...store.state.user, ...response.data[0] }
                };

                axios.defaults.baseURL = updateUser.baseURL;
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.user.bearer;
                store.dispatch('update', updateUser).then(() => next('/'));
            });
            return;
        }
        if (!pageControll.includes(to.name)) {
            LocalApi().set('goBack', to.fullPath);
            next("/login");
        } else {
            next();
        }
    } else {
        let time = store.state.user.exp_at * 1000;
        let date = new Date().getTime();
        let diffTime = Math.floor((Math.abs(time - date)/1000)/60);
        if (time < date) {
            store.dispatch('logout').then(() => {
                LocalApi().set('goBack', to.fullPath);
                next('/login');
            });
        } else if (diffTime < 5) {
            axios.post('v1/admin/authentication/refresh').then(response => {
                const updateUser = { user: { ...store.state.user, ...response.data[0] } };
                store.dispatch('update', updateUser).then(() => {
                    next();
                });
            });
        } else if (pageControll.includes(to.name)) {
            next('/');
        } else {
            next();
        }
    }
});

export default router;