<template>
    <a-dropdown :trigger="['click']" class="border-r border-gray-100 dark:border-gray-700" v-if="!isBreakPointXS">
        <a class="text-gray-700 hover:text-yellow-500 dark:text-white text-xs font-medium px-2 sm:px-5 h-full flex items-center uppercase" @click.prevent>
            {{ $i18n.locale }}
            <DownOutlined class="pl-3" />
        </a>
        <template #overlay>
            <a-menu>
                <a-menu-item v-for="lang in supportLocales" :key="'locale-' + lang.code">
                    <a href="javascript:;" @click="setLocale(lang.code)">{{ lang.name }}</a>
                </a-menu-item>
            </a-menu>
        </template>
    </a-dropdown>
    <a-sub-menu key="sub1" v-else>
        <template #title>
            <span class="text-xs font-medium px-2 sm:px-5 h-full inline-flex items-center">
                <font-awesome-icon icon="language" class="text-lg mr-2" />
                Dil Seçimi
            </span>
        </template>
        <a-menu-item v-for="lang in supportLocales" :key="'locale-' + lang.code">
            <a href="javascript:;" @click="setLocale(lang.code)">{{ lang.name }}</a>
        </a-menu-item>
    </a-sub-menu>
</template>

<script>
    import { DownOutlined } from '@ant-design/icons-vue';

    export default {
        components: {
            DownOutlined
        },
        data() {
            return {
                supportLocales: [
                    { code: 'tr', name: 'Türkçe' },
                    { code: 'en', name: 'English' }
                ]
            };
        },
        methods: {
            setLocale(value) {
                this.$i18n.locale = value;
                this.$store.dispatch("update", {
                    language: value
                }).then(() => {
                    this.$store.dispatch("refresh");
                });
            }
        },
        computed: {
            isBreakPointXS() {
                return this.$store.state.screen == 'xs';
            }
        }
    };
</script>